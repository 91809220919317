import {
    AllEffect,
    CallEffect,
    GenericAllEffect,
    PutEffect,
    SelectEffect,
    TakeEffect,
    all,
    call,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";

// FIXME: circular dependencies between ~/recs-events and ~/action-panel
import * as blendingActions from "~/action-panel/components/common/product-blend-modal/actions";
import * as blendingSelectors from "~/action-panel/components/common/product-blend-modal/selectors";
import * as blending from "~/action-panel/components/common/product-blend-modal/blending-utils";
import { saveCustomProduct } from "~/action-panel/components/common/product-blend-modal/sagas";
import * as recInfoActions from "~/action-panel/components/rec-module/components/rec-info/actions";
import * as recInfoSelectors from "~/action-panel/components/rec-module/components/rec-info/selectors";
import {
    models as rxFileImportModels,
    selectors as rxFileImportSelectors,
} from "~/action-panel/components/rec-module/components/rec-info/components/rec-equation-application/rx-file-import";
import { getFieldGuidToSelectedEventGuidSetMap } from "~/action-panel/components/event-module/components/event-list/selectors";
import * as eventListActions from "~/action-panel/components/event-module/components/event-list/actions";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";

import * as loginActions from "~/login/actions";
import { getTheUserGuid } from "~/login/selectors";
import {
    setFieldsBackgroundOnly,
    setFieldsBackgroundOnlyBatch,
} from "~/map/components/map-control/actions";
import { setActiveToolsetPayloadOnly } from "~/map/components/map-tools/actions";
import { actions as notificationActions } from "~/notifications";
import { RecAPI, APIErrorWithCode, AgEventAPI, FieldAPI, SearchAPI, Polygon } from "@ai360/core";
import { GeometryMath, GeometryUtils } from "@ai360/core";

import * as commonModels from "../model";
import * as recsEventsActions from "../actions";
import { eventsModels } from "../";
import { getZonesState } from "../selectors";
import { getAreaListFromSurface, getFieldBoundaryPolygons } from "../sagas";

import * as models from "./model";
import * as actions from "./actions";
import * as selectors from "./selectors";
import { IRecsState } from "./reducer";

import {
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";
import { setTriggeredPage } from "~/action-panel/components/rec-module/actions";
import { EVENT_TYPE_NAME_HARVEST, EVENT_TYPE_NAME_SAMPLING_SOIL } from "../events/model";
import { OrderedMap } from "immutable";
import { fetchFieldsWithSummaries, IField } from "~/utils/api/summary";
import { IAnalysisLayerSummaryItem } from "../analysis/model";
import { getFieldInfoModel } from "~/action-panel/components/field-module";
import Graphic from "@arcgis/core/Graphic";
import { MAX_FETCH_FIELDS } from "../model";

const ALL = "all";
const NEW = "NEW";
const MANURE = "manure";

const _applyNormalizedYieldLayerToRec = (
    recDetails: models.RecDetails,
    batchLayers: IAnalysisLayerSummaryItem[]
): Readonly<models.RecDetails> => {
    const newProps: Partial<models.RecDetails> = {};
    newProps.recAreaList = recDetails.recAreaList.map((recArea) => {
        return models.RecArea.updateRecArea(recArea, {
            recs: recArea.recs.map((rec) => {
                return _applyNormalizedYieldParameter(rec, recDetails, batchLayers);
            }),
        });
    });
    return models.RecDetails.updateRecDetails(recDetails, newProps);
};

const _applyNormalizedYieldParameter = (
    rec: models.Rec,
    recDetails: models.RecDetails,
    batchLayers: IAnalysisLayerSummaryItem[]
): Readonly<models.Rec> => {
    const newEquationParameters = [...rec.equationParameters];
    const analysisLayerGuid = batchLayers?.find(
        (l) => l.fieldGuid === recDetails.fieldGuid
    )?.analysisLayerGuid;
    const normalizedYieldParameter = rec.equationParameters.find(
        (ep) => ep.name === "Normalized_Yield"
    );

    if (analysisLayerGuid && normalizedYieldParameter) {
        newEquationParameters.splice(newEquationParameters.indexOf(normalizedYieldParameter), 1);
        const newNormalizedYieldParameter = {
            ...normalizedYieldParameter,
            analysisLayerGuid: analysisLayerGuid,
            value: analysisLayerGuid,
        };
        newEquationParameters.push(newNormalizedYieldParameter);
    }
    return models.Rec.updateRec(rec, {
        equationParameters: newEquationParameters,
    });
};

const _getAdjustedProducts = (
    recNutrientProductMixGuid: string,
    targetProducts: models.RecNutrientProduct[]
): models.RecNutrientProduct[] => {
    const revisedProductList = [];
    for (const product of targetProducts) {
        revisedProductList.push({
            ...product,
            recNutrientProductMixGuid: recNutrientProductMixGuid,
        });
    }
    return revisedProductList;
};
const _getCleanedRecNutrient = (recNutrient, newCustomProductGuid: string): models.RecNutrient => {
    return {
        ...recNutrient,
        recNutrientParameters: {
            ...recNutrient.recNutrientParameters,
            minimumLock: false,
            maximumLock: false,
        },
        recNutrientProductMix: {
            ...recNutrient.recNutrientProductMix,
            customProductGuid:
                recNutrient.recNutrientProductMix.customProductGuid === NEW
                    ? newCustomProductGuid
                    : recNutrient.recNutrientProductMix.customProductGuid,
            products: recNutrient.recNutrientProductMix.products.map((product) => {
                return {
                    ...product,
                    customProductGuid:
                        product.customProductGuid === NEW
                            ? newCustomProductGuid
                            : product.customProductGuid,
                };
            }),
        },
    };
};

const _hasNormalizedYieldLayer = (fieldGuidToRecDetails) => {
    const batchData = fieldGuidToRecDetails.get(commonModels.BATCH_TEMPLATE_FIELD_GUID);
    return batchData.recAreaList.some((ra) =>
        ra.recs.some((r) => r.equationParameters.some((ep) => ep.name === "Normalized_Yield"))
    );
};

const _validateEquationSets = (batchRecDetailsForEdit: models.RecDetails[]): boolean => {
    const primaryRecEquationSetGuid =
        batchRecDetailsForEdit[0].recAreaList[0].recs[0].equationGroupGuid;
    return batchRecDetailsForEdit.every((recDetail) =>
        recDetail.recAreaList.every((recArea) =>
            recArea.recs.every((rec) => rec.equationGroupGuid === primaryRecEquationSetGuid)
        )
    );
};

const _reCalculateRecNutrientList = (
    newRecNutrient: models.RecNutrient,
    cleanedRecNutrient: models.RecNutrient,
    currentIndex: number,
    recNutrientList,
    originalTargetRate: number
): models.RecNutrient[] => {
    const minimumIncludeZeros = newRecNutrient.recNutrientParameters.minimumIncludeZeros;
    const fieldRate = Number(
        newRecNutrient.averageAdjustedRecNutrientResult.productRate ||
            newRecNutrient.averageRecNutrientResult.productRate
    );
    const targetRateRatio = blending.isZeroRec(cleanedRecNutrient)
        ? 0
        : Number(cleanedRecNutrient.recNutrientProductMix.targetRate) / Number(originalTargetRate);
    const isMinLock = cleanedRecNutrient.recNutrientParameters.minimumLock;
    const isMaxLock = cleanedRecNutrient.recNutrientParameters.maximumLock;
    const minRate = cleanedRecNutrient.recNutrientParameters.minimumRate;
    const maxRate = cleanedRecNutrient.recNutrientParameters.maximumRate;
    const switchRate = cleanedRecNutrient.recNutrientParameters.switchRate;

    const newProducts = [
        ...newRecNutrient.recNutrientProductMix.products.map((product) => {
            const existingProduct = cleanedRecNutrient.recNutrientProductMix.products.find(
                (existingProduct) =>
                    (existingProduct.productGuid &&
                        existingProduct.productGuid === product.productGuid) ||
                    (existingProduct.customProductGuid &&
                        existingProduct.customProductGuid === product.customProductGuid)
            );

            const isServiceProduct = (existingProduct as any).productParentType === "Service";
            const isAreaOrEach =
                isServiceProduct &&
                ((existingProduct as any).costUnit === "ac" ||
                    (existingProduct as any).costUnit === "ea");
            const adjustmentRatio = isServiceProduct && isAreaOrEach ? 1 : targetRateRatio;

            const fieldRate =
                newRecNutrient.averageAdjustedRecNutrientResult.productRate ||
                newRecNutrient.averageRecNutrientResult.productRate;
            const newRate =
                existingProduct &&
                cleanedRecNutrient.recNutrientProductMix.productMixType.toLowerCase() === MANURE
                    ? Number(fieldRate)
                    : Number(existingProduct.rate) * adjustmentRatio;

            return {
                ...existingProduct,
                ...product,
                rate: blending.roundValue(newRate),
                limeEfficiency: newRecNutrient.recNutrientProductMix.limeEfficiency,
                costPerAcre: blending.roundValue(
                    existingProduct && existingProduct.costPerAcre
                        ? Number(existingProduct.costPerAcre) * adjustmentRatio
                        : 0
                ),
                totalProduct: blending.roundValue(
                    existingProduct && existingProduct.totalProduct
                        ? Number(existingProduct.totalProduct) * adjustmentRatio
                        : 0
                ),
                totalCost: blending.roundValue(
                    existingProduct && existingProduct.totalCost
                        ? Number(existingProduct.totalCost) * adjustmentRatio
                        : 0
                ),
            };
        }),
    ];

    recNutrientList.splice(currentIndex, 1, {
        ...cleanedRecNutrient,
        ...newRecNutrient,
        recNutrientProductMix: {
            ...cleanedRecNutrient.recNutrientProductMix,
            ...newRecNutrient.recNutrientProductMix,
            density: cleanedRecNutrient.recNutrientProductMix.density,
            products: newProducts,
            targetRate: blending.roundValue(
                blending.isZeroRec(cleanedRecNutrient) && minimumIncludeZeros
                    ? cleanedRecNutrient.recNutrientProductMix.targetRate
                    : Number(fieldRate)
            ),
            costPerAcre: blending.roundValue(
                newProducts.reduce((acc, product) => {
                    return acc + product.costPerAcre;
                }, 0)
            ),
            totalProduct: blending.roundValue(
                Number(cleanedRecNutrient.recNutrientProductMix.totalProduct) * targetRateRatio
            ),
            totalCost: blending.roundValue(
                newProducts.reduce((acc, product) => {
                    return acc + product.totalCost;
                }, 0)
            ),
        },
        recNutrientParameters: {
            ...cleanedRecNutrient.recNutrientParameters,
            ...newRecNutrient.recNutrientParameters,
            minimumRate: !isMinLock
                ? newRecNutrient.averageCreditRecNutrientResult.minRate ||
                  newRecNutrient.averageRecNutrientResult.minRate
                : minRate,
            maximumRate: !isMaxLock
                ? newRecNutrient.averageCreditRecNutrientResult.maxRate ||
                  newRecNutrient.averageRecNutrientResult.maxRate
                : maxRate,
            switchRate,
        },
        unitGuid: newRecNutrient.recNutrientProductMix.targetRateUnitGuid,
    });
    return recNutrientList;
};

const _updateAllApplicableRecNutrients = (
    batchRecDetailsForEdit: models.RecDetails[],
    targetRecNutrient,
    newCustomProductGuid: string,
    userGuid: string,
    isBatch: boolean
) => {
    const addUpdateRequests = [];

    for (const recDetails of batchRecDetailsForEdit) {
        for (const recArea of recDetails.recAreaList) {
            for (const rec of recArea.recs) {
                for (const recNutr of rec.recNutrientList) {
                    if (recNutr.nutrientGuid !== targetRecNutrient.nutrientGuid) {
                        continue;
                        //Only update product mixes that are for the same nutrient / keep them in sync
                    }
                    const isTargetRecNutrient =
                        recNutr.recNutrientGuid === targetRecNutrient.recNutrientGuid;
                    const recNutrientParameters = isBatch
                        ? {
                              ...targetRecNutrient.recNutrientParameters,
                              recNutrientParametersGuid:
                                  recNutr.recNutrientParameters.recNutrientParametersGuid,
                              recNutrientGuid: recNutr.recNutrientParameters.recNutrientGuid,
                          }
                        : { ...recNutr.recNutrientParameters };

                    const recNutrientProductMix = isBatch
                        ? {
                              ...targetRecNutrient.recNutrientProductMix,
                              recNutrientProductMixGuid:
                                  recNutr.recNutrientProductMix.recNutrientProductMixGuid,
                              customProductGuid:
                                  targetRecNutrient.recNutrientProductMix.customProductGuid === NEW
                                      ? newCustomProductGuid
                                      : targetRecNutrient.recNutrientProductMix.customProductGuid,
                          }
                        : {
                              ...targetRecNutrient.recNutrientProductMix,
                              recNutrientProductMixGuid:
                                  recNutr.recNutrientProductMix.recNutrientProductMixGuid,
                              products: isTargetRecNutrient
                                  ? targetRecNutrient.recNutrientProductMix.products
                                  : _getAdjustedProducts(
                                        recNutr.recNutrientProductMix.recNutrientProductMixGuid,
                                        targetRecNutrient.recNutrientProductMix.products
                                    ),
                              targetRate: recNutr.recNutrientProductMix.targetRate,
                              targetRateUnit: recNutr.recNutrientProductMix.targetRateUnit,
                              targetRateUnitGuid:
                                  targetRecNutrient.recNutrientProductMix.targetRateUnitGuid ||
                                  recNutr.recNutrientProductMix.targetRateUnitGuid,
                              totalCost: null,
                              totalProduct: null,
                          };
                    const updRecNutrient = {
                        ...recNutr,
                        recNutrientProductMix,
                        recNutrientParameters,
                        unitName: targetRecNutrient.recNutrientProductMix.targetRateUnit,
                        unitGuid: targetRecNutrient.recNutrientProductMix.targetRateUnitGuid,
                    };
                    addUpdateRequests.push(
                        call(RecAPI.addUpdateRecProduct, userGuid, updRecNutrient)
                    );
                }
            }
        }
    }
    return addUpdateRequests;
};

const getAnalysisLayerBatchGuid = (batchData: models.RecDetails): string => {
    const normalizedYieldParameter = batchData.recAreaList
        .find((ra) =>
            ra.recs.find((r) => r.equationParameters.find((ep) => ep.name === "Normalized_Yield"))
        )
        .recs.map((r) => r.equationParameters)[0]
        .find((ep) => ep.name === "Normalized_Yield");
    return normalizedYieldParameter?.value;
};

const getBatchLayers = function* (fieldGuidToRecDetails) {
    const batchToAnalysisLayerMap: OrderedMap<string, IAnalysisLayerSummaryItem[]> = yield select(
        selectors.getBatchToAnalysisLayerMap
    );
    const batchData = fieldGuidToRecDetails.get(commonModels.BATCH_TEMPLATE_FIELD_GUID);
    const batchGuid = getAnalysisLayerBatchGuid(batchData);
    return batchToAnalysisLayerMap.get(batchGuid);
};

export const getRecAreaListFromSurface = function* (
    action: ReturnType<typeof actions.fetchRecDetails>
): Generator<CallEffect | PutEffect<any>, Readonly<models.RecArea>[], any> {
    const classbreakInfo = yield* getAreaListFromSurface(action);
    if (classbreakInfo == null) {
        return;
    }
    const [classIdToClassBreakMap, classIdToGeometryMap] = classbreakInfo;
    const filteredClassBreakMap = [...classIdToClassBreakMap].filter(
        ([classId, value]) => classIdToGeometryMap.has(classId) && value.acreage > 0
    );
    return filteredClassBreakMap.map(([classId, recAreaClassBreak], idx) => {
        const geometry = classIdToGeometryMap.get(classId);
        console.assert(geometry != null);
        const polygons = GeometryMath.getSimplifiedPolygonParts(geometry).map(
            (poly) => new models.RecAreaPolygon(poly)
        );
        const calculatedArea = GeometryUtils.calculateArea(geometry);
        const area = new models.RecArea(polygons, [], calculatedArea);
        return models.RecArea.updateRecArea(area, {
            recAreaId: idx + 1,
            recAreaClassBreak,
        });
    });
};

export const onAddDefaultProduct = function* (
    action: ReturnType<typeof actions.addDefaultProduct>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, recNutrient, recNutrientIdx, recNutrientList } = action.payload;
    const userGuid = yield select(getTheUserGuid);

    try {
        yield put(blendingActions.setIsLoading(true));

        yield call(RecAPI.addDefaultProduct, userGuid, recNutrient);
        recNutrientList.splice(recNutrientIdx, 1, recNutrient);

        yield put(
            recsEventsActions.updateCurrentRecAreaRec(fieldGuid, {
                recNutrientList,
            })
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(blendingActions.setIsLoading(false));
    }
};

export const onAddRecAdjustments = function* (
    action: ReturnType<typeof actions.addRecAdjustments>
): Generator<GenericAllEffect<any> | CallEffect | SelectEffect | PutEffect<any>, any, any> {
    const { recNutrient } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    const currentRecNutrientsInProcessing = yield select(
        recInfoSelectors.getRecNutrientsInProcessing
    );

    try {
        yield put(blendingActions.setIsLoading(true));
        yield put(recInfoActions.setIsProcessingRecNutrient(true));
        const newRecNutrientsProcessing = [
            ...currentRecNutrientsInProcessing,
            recNutrient.recNutrientGuid,
        ];
        yield put(recInfoActions.setRecNutrientsInProcessing(newRecNutrientsProcessing));

        if (recNutrient.recNutrientGuid.indexOf("BATCH_REC_NUTRIENT") >= 0) {
            // Grab, split, and loop through all the appropriate rec nutrients and update them individually
            const adjustmentRequests = [];
            const { batchRecDetailsForEdit } = yield select(recInfoSelectors.getModuleState);
            const batchCreditedGridCells = recNutrient.creditedGridCells;

            for (const recDetails of batchRecDetailsForEdit) {
                const recGeneralGuid = recDetails.recGeneralGuid;
                const recNutrientList = [];
                for (const recArea of recDetails.recAreaList) {
                    for (const rec of recArea.recs) {
                        for (const recNutr of rec.recNutrientList) {
                            if (recNutrient.nutrientGuid !== recNutr.nutrientGuid) {
                                continue;
                            }
                            const minimumIncludeZeros =
                                recNutrient.recNutrientParameters.minimumIncludeZeros;
                            const zerosBelowSwitchRate =
                                recNutrient.recNutrientParameters.zerosBelowSwitchRate;

                            const newCreditedGridCells = batchCreditedGridCells.filter(
                                (cell) => cell.recNutrientGuid === recNutr.recNutrientGuid
                            );
                            let sumProductRate = 0;
                            let appliedCellCount = 0;
                            let actualMin = null;
                            let actualMax = 0;
                            let actualSwitchRate = null;

                            newCreditedGridCells.forEach((gridCell) => {
                                const { productRate } = gridCell;
                                sumProductRate += Number(productRate);
                                appliedCellCount +=
                                    Number(productRate) > 0 || minimumIncludeZeros ? 1 : 0;
                                actualMin =
                                    actualMin == null
                                        ? productRate
                                        : Math.min(productRate, actualMin);
                                actualMax = Math.max(productRate, actualMax);
                                actualSwitchRate = zerosBelowSwitchRate
                                    ? recNutrient.recNutrientParameters.switchRate &&
                                      recNutrient.recNutrientParameters.minimumRate
                                        ? +recNutrient.recNutrientParameters.switchRate *
                                          (actualMin /
                                              +recNutrient.recNutrientParameters.minimumRate)
                                        : recNutrient.recNutrientParameters.switchRate
                                    : null;
                            });

                            const newTargetRate = blending.roundValue(
                                sumProductRate / appliedCellCount
                            );
                            const targetRateRatio =
                                newTargetRate / +recNutrient.recNutrientProductMix.targetRate;
                            // Ratio of this rec target rate to the whole batch, (derived from its updated grid cells)
                            // we can't use the original numbers because it may not actually be the same product(s)

                            const updRecNutrient = {
                                ...recNutr,
                                creditedGridCells: newCreditedGridCells,
                                recNutrientProductMix: {
                                    ...recNutrient.recNutrientProductMix,
                                    recNutrientProductMixGuid:
                                        recNutr.recNutrientProductMix.recNutrientProductMixGuid,
                                    products: recNutrient.recNutrientProductMix.products.map(
                                        (product) => {
                                            return {
                                                ...product,
                                                rate: blending.roundValue(
                                                    +product.rate * targetRateRatio
                                                ),
                                                costPerAcre: blending.roundValue(
                                                    +product.costPerAcre * targetRateRatio
                                                ),
                                                totalProduct: blending.roundValue(
                                                    +product.totalProduct * targetRateRatio
                                                ),
                                                totalCost: blending.roundValue(
                                                    +product.totalCost * targetRateRatio
                                                ),
                                            };
                                        }
                                    ),
                                    targetRate:
                                        +recNutrient.recNutrientProductMix.targetRate *
                                        targetRateRatio,
                                },
                                recNutrientParameters: {
                                    ...recNutrient.recNutrientParameters,
                                    recNutrientParametersGuid:
                                        recNutr.recNutrientParameters.recNutrientParametersGuid,
                                    recNutrientGuid: recNutr.recNutrientParameters.recNutrientGuid,
                                    minimumRate: actualMin,
                                    maximumRate: actualMax,
                                    switchRate: actualSwitchRate,
                                },
                                unitGuid: recNutrient.recNutrientProductMix.targetRateUnitGuid,
                            };
                            recNutrientList.push(updRecNutrient);
                        }
                    }
                }

                adjustmentRequests.push(
                    call(RecAPI.addBatchRecAdjustments, userGuid, {
                        recGeneralGuid,
                        recNutrientList,
                    })
                );
            }
            yield all(adjustmentRequests);
        } else {
            yield call(RecAPI.addRecAdjustments, userGuid, recNutrient);
        }

        const newCurrentRecNutrientsProcessing = yield select(
            recInfoSelectors.getRecNutrientsInProcessing
        );
        yield put(
            recInfoActions.setRecNutrientsInProcessing(newCurrentRecNutrientsProcessing.slice(1))
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(blendingActions.setIsLoading(false));
    }
};

export const onAddUpdateRecProduct = function* (
    action: ReturnType<typeof actions.addUpdateRecProduct>
): Generator<CallEffect | SelectEffect | GenericAllEffect<any> | PutEffect<any>, void, any> {
    const { fieldGuid, recNutrientGuid, recNutrientList } = action.payload;
    const userGuid = yield select(getTheUserGuid);

    try {
        yield put(blendingActions.setIsLoading(true));
        yield put(recInfoActions.setIsProcessingRecNutrient(true));

        let currentIndex = -1;
        const recNutrient = recNutrientList.find((recNutrientItem, recNutrientIndex) => {
            const isMatch = recNutrientItem.recNutrientGuid === recNutrientGuid;
            if (isMatch) {
                currentIndex = recNutrientIndex;
            }
            return isMatch;
        });
        let newCustomProductGuid = "";
        const customProduct = recNutrient.recNutrientProductMix.products.find(
            (product) => product.customProductGuid === NEW
        );
        if (customProduct) {
            newCustomProductGuid = yield call(
                saveCustomProduct,
                blendingActions.saveCustomProduct(customProduct)
            );
        }
        const cleanedRecNutrient = _getCleanedRecNutrient(recNutrient, newCustomProductGuid);

        if (cleanedRecNutrient.recNutrientGuid.indexOf("BATCH_REC_NUTRIENT") >= 0) {
            // Grab, split, and loop through all the appropriate rec nutrients and update them individually
            const { batchRecDetailsForEdit } = yield select(recInfoSelectors.getModuleState);
            const addUpdateRequests = _updateAllApplicableRecNutrients(
                batchRecDetailsForEdit,
                recNutrient,
                newCustomProductGuid,
                userGuid,
                true
            );
            const updatedRecNutrientList = yield all(addUpdateRequests);
            const newBatchRecDetailsForEdit: models.RecDetails[] = batchRecDetailsForEdit.map(
                (recDetails) => {
                    return {
                        ...recDetails,
                        recAreaList: recDetails.recAreaList.map((recArea) => {
                            const newRecsList = recArea.recs.map((rec) => {
                                const newRecNutrient = updatedRecNutrientList.find((ur) =>
                                    recArea.recs[0].recNutrientList.find(
                                        (recN) => recN.recNutrientGuid === ur.recNutrientGuid
                                    )
                                );
                                if (!newRecNutrient) {
                                    return rec;
                                }
                                const newCleanedRecNutrient = _getCleanedRecNutrient(
                                    newRecNutrient,
                                    newCustomProductGuid
                                );
                                const originalTargetRate =
                                    +recNutrientList[currentIndex].recNutrientProductMix.targetRate;
                                const revisedRecNutrientList = _reCalculateRecNutrientList(
                                    newRecNutrient,
                                    newCleanedRecNutrient,
                                    currentIndex,
                                    recArea.recs[0].recNutrientList,
                                    originalTargetRate
                                );

                                return models.Rec.updateRec(rec, {
                                    recNutrientList: revisedRecNutrientList,
                                });
                            });
                            return models.RecArea.updateRecArea(recArea, {
                                recs: newRecsList,
                            });
                        }),
                    };
                }
            );
            yield put(recInfoActions.setBatchRecDetailsForEdit(newBatchRecDetailsForEdit));
            const newRecAreaList = blending.mergeRecDetailInfo(newBatchRecDetailsForEdit);

            yield put(actions.updateBatchRecDetails(newRecAreaList));
            yield put(
                actions.updateCurrentRecAreaRec(fieldGuid, {
                    recNutrientList: newRecAreaList[0].recs[0].recNutrientList,
                })
            );
        } else {
            //Update all zones for non-batch case as well
            const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);
            const recDetails = fieldGuidToRecDetails.get(fieldGuid);
            const addUpdateRequests = _updateAllApplicableRecNutrients(
                [recDetails],
                cleanedRecNutrient,
                newCustomProductGuid,
                userGuid,
                false
            );
            const updatedRecNutrientList = yield all(addUpdateRequests);
            const newRecDetails = {
                ...recDetails,
                recAreaList: recDetails.recAreaList.map((recArea) => {
                    const newRecsList = recArea.recs.map((rec) => {
                        const newRecNutrientList = rec.recNutrientList.map((recNut) => {
                            const updatedRecNutrient =
                                updatedRecNutrientList.find(
                                    (recN) => recN.recNutrientGuid === recNut.recNutrientGuid
                                ) || recNut;
                            return {
                                ...updatedRecNutrient,
                                unitGuid:
                                    updatedRecNutrient.unitGuid ||
                                    recNutrient.unitGuid ||
                                    recNutrient.recNutrientProductMix.targetRateUnitGuid,
                                unitName:
                                    updatedRecNutrient.unitName ||
                                    recNutrient.unitName ||
                                    recNutrient.recNutrientProductMix.targetRateUnit,
                            };
                        });
                        return models.Rec.updateRec(rec, {
                            recNutrientList: newRecNutrientList,
                        });
                    });
                    return models.RecArea.updateRecArea(recArea, {
                        recs: newRecsList,
                    });
                }),
            };

            for (const recArea of newRecDetails.recAreaList) {
                const newRecNutrient = updatedRecNutrientList.find((ur) =>
                    recArea.recs[0].recNutrientList.find(
                        (recN) => recN.recNutrientGuid === ur.recNutrientGuid
                    )
                );
                if (!newRecNutrient) {
                    continue;
                }
                const newCleanedRecNutrient = _getCleanedRecNutrient(
                    newRecNutrient,
                    newCustomProductGuid
                );
                const originalTargetRate =
                    +recNutrientList[currentIndex].recNutrientProductMix.targetRate;
                const revisedRecNutrientList = _reCalculateRecNutrientList(
                    newRecNutrient,
                    newCleanedRecNutrient,
                    currentIndex,
                    recArea.recs[0].recNutrientList,
                    originalTargetRate
                );
                yield put(
                    recsEventsActions.updateRecModel(fieldGuid, recArea.recAreaId, false, {
                        recNutrientList: revisedRecNutrientList,
                    })
                );
                yield put(blendingActions.setCreditedGridCells(newRecNutrient.creditedGridCells));
            }
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(blendingActions.setIsLoading(false));
    }
};

export const onCopyBatchTemplateToRecs = function* (
    action: ReturnType<typeof actions.copyBatchTemplateToRecs>
): Generator<AllEffect | CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const userGuid = yield select(getTheUserGuid);
    const { fieldGuidToRecDetails }: IRecsState = yield select(selectors.getModuleState);

    // Update the times strings to match the format expected for upload
    const recDetailsList = [...fieldGuidToRecDetails.values()].map((recDetails) =>
        models.RecDetails.updateRecDetails(recDetails, {
            recDate: `${recDetails.momentCreatedDate.format("YYYY-MM-DD")}T20:00:00.000Z`,
        })
    );

    try {
        yield call(RecAPI.validRecGeneral, userGuid, recDetailsList);
    } catch (err) {
        if (!(err instanceof APIErrorWithCode)) {
            yield put(notificationActions.apiCallError(err, action));
        } else {
            yield put(
                actions.saveRecDetailsFailed(
                    err.errorCodeList,
                    Array.from(fieldGuidToRecDetails.keys())
                )
            );
        }
        return;
    }

    // Handle Management Area properties
    const { managementAreaLayerOptionsMap } = yield select(recInfoSelectors.getModuleState);
    if (managementAreaLayerOptionsMap.size > 0) {
        const recsToUpdate = [...fieldGuidToRecDetails.values()]
            .filter((r) => r.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID)
            .map((recDetails) => {
                const newProps: Partial<models.RecDetails> = {};
                // Set all recs to using every available management area by default.
                const maLayers = managementAreaLayerOptionsMap.get(recDetails.fieldGuid);
                if (maLayers && maLayers.length > 0) {
                    newProps.recAreaList = recDetails.recAreaList.map((recArea) => {
                        return models.RecArea.updateRecArea(recArea, {
                            recs: recArea.recs.map((rec) => {
                                const newEquationParameters = [...rec.equationParameters];
                                const managementAreaParameter = newEquationParameters.find(
                                    (eqParam) => eqParam.name === "Management_Area"
                                );
                                if (managementAreaParameter) {
                                    newEquationParameters.splice(
                                        newEquationParameters.indexOf(managementAreaParameter),
                                        1
                                    );
                                    const newManagementAreaParameter = {
                                        ...managementAreaParameter,
                                    };
                                    newManagementAreaParameter.value = maLayers
                                        .map((maLayer) => maLayer.value)
                                        .join(",");
                                    newManagementAreaParameter.managementAreaAnalysisLayerGuids =
                                        maLayers.map((maLayer) => maLayer.value);
                                    newEquationParameters.push(newManagementAreaParameter);
                                }
                                return models.Rec.updateRec(rec, {
                                    equationParameters: newEquationParameters,
                                });
                            }),
                        });
                    });
                }
                return models.RecDetails.updateRecDetails(recDetails, newProps);
            });
        for (const recDetails of recsToUpdate) {
            yield put(
                actions.updateRecDetails(recDetails.fieldGuid, {
                    recAreaList: recDetails.recAreaList,
                })
            );
        }
    }

    // Handle RX File Import
    const { matchData }: rxFileImportModels.IRXFileImportState = yield select(
        rxFileImportSelectors.getModuleState
    );
    if (matchData) {
        const batchData = fieldGuidToRecDetails.get(commonModels.BATCH_TEMPLATE_FIELD_GUID);

        const recNutrientToColumnMap = new Map();
        batchData.recAreaList[0].recs[0].recNutrientList.forEach((recNutrient, index) => {
            recNutrientToColumnMap.set(index, recNutrient.importFileColumnName);
        });
        const recsToUpdate = [...fieldGuidToRecDetails.values()]
            .filter((r) => r.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID)
            .map((recDetails) => {
                const newRecAreaList = recDetails.recAreaList.map((recArea) => {
                    const newRecList = recArea.recs.map((rec) => {
                        const newRecNutrientList = rec.recNutrientList
                            .map((recNutrient, index) => {
                                const column = recNutrientToColumnMap.get(index);
                                const columnFieldMatch = matchData.matches.find(
                                    (match) =>
                                        match.fieldGuids.includes(recDetails.fieldGuid) &&
                                        match.columns.includes(column)
                                );
                                if (columnFieldMatch) {
                                    recNutrient.importFileGuid = columnFieldMatch.importFileGuid;
                                }
                                recNutrient.importFileColumnName = column;
                                return { ...recNutrient };
                            })
                            .filter((recNutrient) => recNutrient.importFileGuid);
                        return models.Rec.updateRec(rec, { recNutrientList: newRecNutrientList });
                    });
                    return models.RecArea.updateRecArea(recArea, { recs: newRecList });
                });
                return put(
                    recsEventsActions.updateRecDetails(recDetails.fieldGuid, {
                        recAreaList: newRecAreaList,
                    })
                );
            });
        yield all(recsToUpdate);
    }
    if (_hasNormalizedYieldLayer(fieldGuidToRecDetails)) {
        yield* onUpdateNormalizedYieldLayers(fieldGuidToRecDetails);
    }

    yield put(recsEventsActions.setCurrentBatchFieldGuid(null));
    yield put(recsEventsActions.initializeRecsZones());
};

export const onCopyRecDetails = function* (
    action: ReturnType<typeof actions.copyRecDetails>
): Generator<
    | Generator<CallEffect | SelectEffect | PutEffect<any>, void, unknown>
    | CallEffect
    | SelectEffect
    | PutEffect<any>
    | TakeEffect,
    void,
    any
> {
    yield put(actions.fetchRecDetails(action.payload.recGeneralGuid));
    const fetchSuccessfulAction = yield take(actions.FETCH_REC_DETAILS_SUCCEEDED);
    const { recDetails } = fetchSuccessfulAction.payload;
    if (recDetails.recGeneralGuid !== action.payload.recGeneralGuid) {
        return;
    }

    const updateAreaAction = yield take(recsEventsActions.UPDATE_REC_AREA_POLYGONS);
    const { areaIdToNewAreaIdPolygonMap } = updateAreaAction.payload;

    const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);
    console.assert(fieldGuidToRecDetails.size === 1);

    // Reset `recGeneralGuid` and update the date to the default for new rec
    const fieldGuid = fieldGuidToRecDetails.keys().next().value;
    const { recGeneralGuid, momentCreatedDate } = new models.RecDetails("", "", [], null);
    const newRecAreaList = recDetails.recAreaList.map((recArea) => {
        const { applyRecToArea, calculatedArea, recAreaId } = recArea;
        const recs = recArea.recs.map((rec) => {
            return models.Rec.updateRec(rec, {
                recGuid: null,
                recAreaGuid: "",
            });
        });
        const { fieldBoundaryGuid, shape } = areaIdToNewAreaIdPolygonMap
            .get(recArea.recAreaId)
            .get(recArea.recAreaId);
        const zonePolygons = GeometryMath.getSimplifiedPolygonParts(shape).map(
            (poly) => new models.RecAreaPolygon(poly)
        );
        const newRecArea = new models.RecArea(zonePolygons, recs, calculatedArea, applyRecToArea);
        newRecArea.recAreaClassBreak = recArea.recAreaClassBreak;
        return models.RecArea.updateRecArea(newRecArea, {
            recAreaId,
            fieldBoundaryGuid,
        });
    });
    yield put(
        actions.updateRecDetails(fieldGuid, {
            recGeneralGuid,
            momentCreatedDate,
            recAreaList: newRecAreaList,
        })
    );

    yield put(actions.setRecIsCopyAction(false));
    yield put(actions.setRecBoundaryGraphic(null));
};

export const onCreateNewBatchRec = function* (
    action: ReturnType<typeof actions.createNewBatchRec>
): Generator<PutEffect<any> | PutEffect<any>, void, any> {
    const { fieldGuidList, fieldGuidToBoundaryGuidMap, recTypeInfo } = action.payload;
    yield put(setFieldsBackgroundOnlyBatch(true));
    fieldGuidList.splice(0, 0, commonModels.BATCH_TEMPLATE_FIELD_GUID);
    yield put(actions.createNewRecDetails(fieldGuidList, fieldGuidToBoundaryGuidMap, recTypeInfo));
};

export const onShowBatchRecsEdit = function* (
    action: ReturnType<typeof actions.showBatchRecsEdit>
): Generator<CallEffect | PutEffect<any>, void, any> {
    const {
        selectedRecGuidSet,
        recGeneralGuidToRecMap,
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
    } = action.payload;
    const recSummaries = [];
    for (const guid of selectedRecGuidSet) {
        const recSummary = recGeneralGuidToRecMap.get(guid);
        recSummaries.push(recSummary);
    }
    const primaryRecSummary = { ...recSummaries[0], isBatchEdit: true };
    const recTypeInfo = {
        name: models.REC_TYPE_NAME_EQUATION_APPLICATION,
        recTypeGuid: primaryRecSummary.recTypeGuid,
    };
    yield put(recInfoActions.setBatchRecSummariesForEdit(recSummaries));
    yield put(setFieldsBackgroundOnlyBatch(true));
    fieldGuidList.splice(0, 0, commonModels.BATCH_TEMPLATE_FIELD_GUID);

    yield put(actions.createNewRecDetails(fieldGuidList, fieldGuidToBoundaryGuidMap, recTypeInfo));
    yield put(recInfoActions.setRecDetailsLoading(true));
    yield call(onFetchBatchRecDetails, { type: actions.FETCH_BATCH_REC_DETAILS });
};

export const onFetchBatchRecDetails = function* (
    action: ReturnType<typeof actions.fetchBatchRecDetails>
): Generator<SelectEffect | GenericAllEffect<any> | PutEffect<any> | PutEffect<any>, void, any> {
    const { batchRecSummariesForEdit } = yield select(recInfoSelectors.getModuleState);

    const batchRecDetailsForEdit = [];
    const getRecRequests = [];
    const userGuid = yield select(getTheUserGuid);
    for (const recSummary of batchRecSummariesForEdit) {
        yield put(recInfoActions.setRecDetailsLoading(true));
        getRecRequests.push(call(RecAPI.getRec, userGuid, recSummary.recGeneralGuid));
    }
    try {
        const recResponses = yield all(getRecRequests);
        for (const response of recResponses) {
            batchRecDetailsForEdit.push(models.RecDetails.fromJsonObj(response));
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
    // Check rec details for them all being the same equationSetGuid
    const allSameEquationSet = _validateEquationSets(batchRecDetailsForEdit);
    const batchGuid = commonModels.BATCH_TEMPLATE_FIELD_GUID;
    yield put(recInfoActions.setBatchRecDetailsForEdit(batchRecDetailsForEdit));
    if (!allSameEquationSet) {
        yield put(recInfoActions.toggleBatchEditRecsModal(true));
    }

    const recAreaList = blending.mergeRecDetailInfo(batchRecDetailsForEdit);

    yield put(
        actions.updateRecDetails(batchGuid, {
            recGeneralGuid: "BATCH_REC_GENERAL_GUID",
            recAreaList,
        })
    );
    yield put(recInfoActions.setRecDetailsLoading(false));
};

export const onCreateNewClassifiedRec = function* (
    action:
        | ReturnType<typeof actions.createNewClassifiedRec>
        | ReturnType<typeof actions.fetchRecDetails>
): Generator<CallEffect | PutEffect<any> | SelectEffect, void, any> {
    const { field, recTypeInfo, triggeredPage } = (
        action as ReturnType<typeof actions.createNewClassifiedRec>
    ).payload;

    if (triggeredPage) {
        yield put(setTriggeredPage(triggeredPage));
    }

    const recAreaList = yield* getRecAreaListFromSurface(
        action as ReturnType<typeof actions.fetchRecDetails>
    );
    const picklistOptionsCroppingSeason = yield select(
        picklistSelectors.getPicklistOptionsFromCode,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
    );
    const curYearStr = String(new Date().getFullYear());
    const defaultOption = picklistOptionsCroppingSeason.find(
        (option) => option.label === curYearStr
    );
    const croppingSeasonGuid = defaultOption ? defaultOption.value : "";
    yield put(
        actions.createNewRecDetails(
            [field.fieldGuid],
            new Map([[field.fieldGuid, field.fieldBoundaryGuid]]),
            recTypeInfo,
            recAreaList,
            croppingSeasonGuid
        )
    );
};

export const onCreateNewRecDetails = function* (): Generator<
    CallEffect | SelectEffect | PutEffect<any>,
    void,
    any
> {
    const userGuid = yield select(getTheUserGuid);
    const fieldGuidToRecDetails: OrderedMap<string, models.RecDetails> = yield select(
        selectors.getFieldGuidToRecDetails
    );
    const fieldGuidToSelectedEventGuidSetMap = yield select(getFieldGuidToSelectedEventGuidSetMap);
    const firstRecDetails = [...fieldGuidToRecDetails.values()][0];
    const isEquation =
        firstRecDetails.recType === models.REC_TYPE_NAME_EQUATION_APPLICATION ||
        firstRecDetails.recType === models.REC_TYPE_NAME_EQUATION_PLANTING;
    yield put(setFieldsBackgroundOnly(true));

    const fieldGuidToEventListMap = new Map();

    if (isEquation) {
        const fieldGuids = Array.from(fieldGuidToRecDetails.keys()).filter(
            (r) => r !== commonModels.BATCH_TEMPLATE_FIELD_GUID
        );

        const fieldListJsonResp: IField<FieldAPI.IAgEventSummary>[] = yield call(
            fetchFieldsWithSummaries,
            fieldGuids,
            userGuid,
            AgEventAPI.fetchSummaries
        );

        fieldListJsonResp.forEach((field) => {
            fieldGuidToEventListMap.set(
                field.id,
                field.summaries.map((e) => eventsModels.AgEventSummary.fromJsonObj(e, field))
            );
        });
    }

    for (const [fieldGuid, recDetails] of fieldGuidToRecDetails.entries()) {
        console.assert(recDetails.recAreaList.length >= 1);
        const { recAreaId } = recDetails.recAreaList[0];
        yield put(recsEventsActions.setCurrentAreaId(fieldGuid, recAreaId));

        if (isEquation && fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID) {
            const selectedEventGuidSet =
                fieldGuidToSelectedEventGuidSetMap.get(fieldGuid) || new Set(); // Explicitly selected events

            const fieldEventList: AgEventAPI.IAgEventSummary[] = (
                fieldGuidToEventListMap.get(fieldGuid) || []
            ).filter((e) => {
                const isSamplingWithResults =
                    [EVENT_TYPE_NAME_SAMPLING_SOIL].indexOf(e.agEventTypeName) > -1 &&
                    e.hasSoilSampleResults;
                const isImportHarvest =
                    [EVENT_TYPE_NAME_HARVEST].indexOf(e.agEventTypeName) > -1 && e.isImportedYn;

                return (isSamplingWithResults || isImportHarvest) && e.activeYn;
            });
            const selectedFieldEvents = fieldEventList.filter((e) =>
                selectedEventGuidSet.has(e.agEventGeneralGuid)
            );

            const isDefaultSelection = !selectedFieldEvents || selectedFieldEvents.length === 0;
            const currentYear = new Date().getFullYear();

            const defaultEvents = fieldEventList
                .filter(
                    (e) => Number(e.croppingSeasonName) <= currentYear && e.hasSoilSampleResults
                )
                .sort((a, b) =>
                    a.croppingSeasonName < b.croppingSeasonName
                        ? 1
                        : a.croppingSeasonName > b.croppingSeasonName
                        ? -1
                        : a.eventDate < b.eventDate
                        ? 1
                        : a.eventDate > b.eventDate
                        ? -1
                        : a.modifiedDate < b.modifiedDate
                        ? 1
                        : a.modifiedDate > b.modifiedDate
                        ? -1
                        : 0
                );

            const mostRecentSeason =
                defaultEvents.length === 0 ? currentYear : defaultEvents[0].croppingSeasonName;

            const eventsToRec = isDefaultSelection
                ? defaultEvents.filter(
                      (e) =>
                          [EVENT_TYPE_NAME_SAMPLING_SOIL].indexOf(e.agEventTypeName) > -1 &&
                          e.hasSoilSampleResults &&
                          e.croppingSeasonName === mostRecentSeason
                  )
                : selectedFieldEvents;

            const eventSelectionList = Array.from(eventsToRec)?.map((e) => {
                return new models.RecEventSelection(
                    null,
                    null,
                    e.agEventGeneralGuid,
                    e.croppingSeasonName,
                    e.agEventTypeName,
                    isDefaultSelection
                );
            });

            yield put(
                eventListActions.selectEventsForFieldGuid(
                    fieldGuid,
                    eventSelectionList.map((e) => e.agEventGeneralGuid)
                )
            );

            yield put(actions.updateRecDetails(fieldGuid, { eventSelectionList }));
        }
    }
    //Changed rec-info saga to listen to this action due to the fact
    //that added listening for same action on both caused a cancel effect when
    //yield was added to set backgrounds fields.  Alternatively, setBackgroundField
    //can be moved to after setCurrentAreaId, but would run into the same issue
    //if anyone needed to add a yield before the setCurrentAreaId in the future
    yield put(recsEventsActions.currentAreaIdsSet());
};

const onUpdateNormalizedYieldLayers = function* (
    fieldGuidToRecDetails: OrderedMap<string, models.RecDetails>
) {
    const batchLayers = yield* getBatchLayers(fieldGuidToRecDetails);
    const recsToUpdate = [...fieldGuidToRecDetails.values()]
        .filter((r) => r.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID)
        .map((recDetails) => {
            return _applyNormalizedYieldLayerToRec(recDetails, batchLayers);
        });

    for (const recDetails of recsToUpdate) {
        yield put(
            actions.updateRecDetails(recDetails.fieldGuid, {
                recAreaList: recDetails.recAreaList,
            })
        );
    }
};

export const onUpdateRecSeasonDependencies = function* (
    action: ReturnType<typeof recInfoActions.updateRecSeasonDependencies>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, seasonName } = action.payload;

    const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);
    const firstRecDetails = [...fieldGuidToRecDetails.values()][0];
    const isEquation =
        firstRecDetails.recType === models.REC_TYPE_NAME_EQUATION_APPLICATION ||
        firstRecDetails.recType === models.REC_TYPE_NAME_EQUATION_PLANTING;

    const fieldGuidToEventListMap = new Map();

    const fieldGuids = Array.from(fieldGuidToRecDetails.keys()).filter((r) => {
        if (r === commonModels.BATCH_TEMPLATE_FIELD_GUID) {
            return false;
        }

        return fieldGuid === commonModels.BATCH_TEMPLATE_FIELD_GUID || fieldGuid === r;
    });

    const userGuid = yield select(getTheUserGuid);

    const fieldListJsonResp: IField<FieldAPI.IAgEventSummary>[] = yield call(
        fetchFieldsWithSummaries,
        fieldGuids,
        userGuid,
        AgEventAPI.fetchSummaries
    );

    fieldListJsonResp.forEach((field) => {
        fieldGuidToEventListMap.set(
            field.id,
            field.summaries.map((e) => eventsModels.AgEventSummary.fromJsonObj(e, field))
        );
    });

    for (const [recFieldGuid, recDetails] of fieldGuidToRecDetails.entries()) {
        const isDefaultSelection =
            recDetails.eventSelectionList.length === 0 ||
            recDetails.eventSelectionList.every((e) => e.isDefaultSelection);
        const shouldUpdate =
            recFieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID &&
            isDefaultSelection &&
            (fieldGuid === commonModels.BATCH_TEMPLATE_FIELD_GUID ||
                fieldGuid === recDetails.fieldGuid);

        if (isEquation && shouldUpdate) {
            const fieldEventList: AgEventAPI.IAgEventSummary[] = (
                fieldGuidToEventListMap.get(recFieldGuid) || []
            ).filter((e) => {
                const isSamplingWithResults =
                    [EVENT_TYPE_NAME_SAMPLING_SOIL].indexOf(e.agEventTypeName) > -1 &&
                    e.hasSoilSampleResults;

                return isSamplingWithResults && e.activeYn;
            });

            const defaultEvents = fieldEventList
                .filter((e) => Number(e.croppingSeasonName) <= seasonName && e.hasSoilSampleResults)
                .sort((a, b) =>
                    a.croppingSeasonName < b.croppingSeasonName
                        ? 1
                        : a.croppingSeasonName > b.croppingSeasonName
                        ? -1
                        : a.eventDate < b.eventDate
                        ? 1
                        : a.eventDate > b.eventDate
                        ? -1
                        : a.modifiedDate < b.modifiedDate
                        ? 1
                        : a.modifiedDate > b.modifiedDate
                        ? -1
                        : 0
                );

            const mostRecentSeason =
                defaultEvents.length === 0 ? seasonName : defaultEvents[0].croppingSeasonName;

            const eventsToRec = defaultEvents.filter(
                (e) =>
                    [EVENT_TYPE_NAME_SAMPLING_SOIL].indexOf(e.agEventTypeName) > -1 &&
                    e.hasSoilSampleResults &&
                    e.croppingSeasonName === mostRecentSeason
            );

            const eventSelectionList = Array.from(eventsToRec)?.map((e) => {
                return new models.RecEventSelection(
                    null,
                    null,
                    e.agEventGeneralGuid,
                    e.croppingSeasonName,
                    e.agEventTypeName,
                    isDefaultSelection
                );
            });

            yield put(
                eventListActions.selectEventsForFieldGuid(
                    recFieldGuid,
                    eventSelectionList.map((e) => e.agEventGeneralGuid)
                )
            );

            yield put(actions.updateRecDetails(recFieldGuid, { eventSelectionList }));
        }
    }
};

export const onDeleteRecs = function* (
    action: ReturnType<typeof actions.deleteRecs>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { recGeneralGuidList } = action.payload;
    const userGuid = yield select(getTheUserGuid);

    try {
        yield call(RecAPI.deleteRecs, userGuid, recGeneralGuidList);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }
    yield put(actions.deleteRecsSuccessful());
};

export const onActivateRec = function* (
    action: ReturnType<typeof actions.activateRec>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { agRecGeneralGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);

    try {
        yield call(RecAPI.activateRec, userGuid, agRecGeneralGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }
    yield put(actions.activateRecSuccessful());
};

export const onFetchRecDetails = function* (
    action: ReturnType<typeof actions.fetchRecDetails>
): Generator<CallEffect | TakeEffect | SelectEffect | PutEffect<any>, void, any> {
    const { recGeneralGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const response = yield call(RecAPI.getRec, userGuid, recGeneralGuid);
        const recDetails = models.RecDetails.fromJsonObj(response);

        const isEquation =
            recDetails.recType === models.REC_TYPE_NAME_EQUATION_APPLICATION ||
            recDetails.recType === models.REC_TYPE_NAME_EQUATION_PLANTING;

        if (isEquation && recDetails.eventSelectionList?.length > 0) {
            const selectedEventGuids =
                recDetails.eventSelectionList.map((e) => e.agEventGeneralGuid) || [];

            const userGuid = yield select(getTheUserGuid);

            const fieldListJsonResp: IField<FieldAPI.IAgEventSummary>[] = yield call(
                fetchFieldsWithSummaries,
                [recDetails.fieldGuid],
                userGuid,
                AgEventAPI.fetchSummaries
            );

            const fieldJsonResp = fieldListJsonResp.length > 0 ? fieldListJsonResp[0] : null;
            const eventListJsonResp = fieldJsonResp?.summaries;
            const fieldEventList = eventListJsonResp
                ?.map((e) => eventsModels.AgEventSummary.fromJsonObj(e, fieldJsonResp))
                .filter((e) => selectedEventGuids.indexOf(e.agEventGeneralGuid) > -1);

            const eventSelectionList = recDetails.eventSelectionList?.map((e) => {
                const eventSummary = fieldEventList.find(
                    (agEvent) => agEvent.agEventGeneralGuid === e.agEventGeneralGuid
                );
                return new models.RecEventSelection(
                    e.recEventSelectionGuid,
                    e.recGeneralGuid,
                    e.agEventGeneralGuid,
                    eventSummary.croppingSeasonName,
                    eventSummary.agEventTypeName,
                    e.isDefaultSelection
                );
            });

            const updatedRecDetails = models.RecDetails.updateRecDetails(recDetails, {
                eventSelectionList,
            });
            yield put(
                eventListActions.selectEventsForFieldGuid(recDetails.fieldGuid, selectedEventGuids)
            );
            yield put(actions.fetchRecDetailsSucceeded(updatedRecDetails));
        } else {
            const croppingSeasonPicklistCode = picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROPPING_SEASON
            );
            yield put(
                picklistActions.fetchPicklistData({
                    [picklistNames.PICKLIST_CROPPING_SEASON]: croppingSeasonPicklistCode,
                })
            );
            yield take(picklistActions.fetchedPicklistData);
            const picklistOptionsCroppingSeason = yield select(
                picklistSelectors.getPicklistOptionsFromCode,
                croppingSeasonPicklistCode
            );
            const seasonName = picklistOptionsCroppingSeason.find(
                (c) => c.value === recDetails.croppingSeasonGuid
            );
            yield put(actions.fetchRecDetailsSucceeded(recDetails));
            yield put(
                recInfoActions.updateRecSeasonDependencies(recDetails.fieldGuid, seasonName?.label)
            );
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const onFetchRecDetailsSucceeded = function* (
    action: ReturnType<typeof actions.fetchRecDetailsSucceeded>
): Generator<PutEffect<any>, void, any> {
    const { recDetails } = action.payload;

    const zonesIncludedInRec = recDetails.recAreaList.filter((recArea) => recArea.applyRecToArea);
    const recAreaId = Math.min(...zonesIncludedInRec.map((recArea) => recArea.recAreaId));

    yield put(recsEventsActions.setCurrentAreaId(recDetails.fieldGuid, recAreaId));
    if (recDetails.displayName === "Batch Edit") {
        // TODO: use better way to find out if it is an aggregate view for batch rec edit
        yield put(recsEventsActions.currentAreaIdsSet());
    }
};

export const onFetchRecIntegrationPartnerAccess = function* (): Generator<
    CallEffect | TakeEffect | SelectEffect | PutEffect<any>,
    void,
    any
> {
    const userGuid = yield select(getTheUserGuid);

    try {
        const access = yield call(RecAPI.getRecIntegrationPartnerAccess, userGuid);
        yield put(actions.setRecIntegrationPartnerAccess(access));
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    }
};

export const onInitializeRecsZones = function* (
    action: ReturnType<typeof actions.initializeRecsZones>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);

    const recDetailsList = [...fieldGuidToRecDetails.values()];
    const fieldGuidList = recDetailsList
        .map((recDetails) => {
            console.assert(recDetails.recAreaList.length === 1);
            console.assert(recDetails.recAreaList[0].eventAreaPolygon == null);
            return recDetails.fieldGuid;
        })
        .filter((fieldGuid) => fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID);

    const getAreaIdFromFieldGuid = (fieldGuid) => {
        const { recAreaId } = fieldGuidToRecDetails.get(fieldGuid).recAreaList[0];
        return recAreaId;
    };
    let fieldGuidToAreaUpdateMap;
    try {
        fieldGuidToAreaUpdateMap = yield* getFieldBoundaryPolygons(
            fieldGuidList,
            getAreaIdFromFieldGuid
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }

    for (const [fieldGuid, areaIdToNewAreaIdPolygonMap] of fieldGuidToAreaUpdateMap.entries()) {
        yield put(actions.updateRecAreaPolygons(fieldGuid, areaIdToNewAreaIdPolygonMap));
    }
    yield put(actions.initializeRecsZonesSucceeded());
};

export const onInitializeRecsZonesSucceeded = function* () {
    const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);
    const conversionFactors = yield select(blendingSelectors.getConversionFactors);
    const productBlendPicklists = yield select(blendingSelectors.getProductBlendPicklists);
    const props = { conversionFactors, productBlendPicklists };

    const recsToUpdate: models.RecDetails[] = [...fieldGuidToRecDetails.values()]
        .filter((r) => r.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID)
        .map((recDetails) => {
            const newProps: Partial<models.RecDetails> = {};
            if (recDetails.recType === models.REC_TYPE_NAME_MANUAL_APPLICATION) {
                newProps.recAreaList = recDetails.recAreaList.map((recArea) => {
                    return models.RecArea.updateRecArea(recArea, {
                        recs: recArea.recs.map((rec) => {
                            return models.Rec.updateRec(rec, {
                                productMixList: rec.productMixList.map((mix) => {
                                    return {
                                        ...mix,
                                        products: mix.products.map((product) => {
                                            const isServiceProduct =
                                                product.productParentType === "Service";
                                            if (!isServiceProduct) {
                                                return { ...product };
                                            }

                                            const serviceProductConversionFactor =
                                                blending.getServiceProductCostConversionFactor(
                                                    blending.getPriceUnitFromRateUnit(
                                                        blending.getRateUnit(
                                                            mix.targetRateUnitGuid,
                                                            props
                                                        ),
                                                        props
                                                    )?.value,
                                                    product.costUnitGuid,
                                                    mix.density,
                                                    props
                                                );

                                            const totalServiceProduct =
                                                product.costUnit === "ac"
                                                    ? recArea.calculatedArea
                                                    : product.costUnit === "ea"
                                                    ? 1
                                                    : mix.targetRate *
                                                      recArea.calculatedArea *
                                                      serviceProductConversionFactor;

                                            return {
                                                ...product,
                                                rate: totalServiceProduct,
                                                totalProduct: totalServiceProduct,
                                            };
                                        }),
                                    };
                                }),
                            });
                        }),
                    });
                });
                return models.RecDetails.updateRecDetails(recDetails, newProps);
            }
        })
        .filter((r) => r);

    for (const recDetails of recsToUpdate) {
        yield put(
            actions.updateRecDetails(recDetails.fieldGuid, {
                recAreaList: recDetails.recAreaList,
            })
        );
    }
};

export const onResetCurrentRecAreaRec = function* (
    action: ReturnType<typeof actions.resetCurrentRecAreaRec>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid } = action.payload;
    const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);
    const { fieldGuidToCurrentAreaId } = yield select(getZonesState);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);
    const currentAreaId = fieldGuidToCurrentAreaId.get(fieldGuid);
    const recArea = recDetails.recAreaList.find((recArea) => recArea.recAreaId === currentAreaId);
    const rec = recArea.recs[0];
    const { recGuid, recAreaGuid } = rec;
    const newRec = models.Rec.resetRecModel(rec);
    yield put(
        actions.updateCurrentRecAreaRec(
            fieldGuid,
            newRec.updateRecModel({
                recGuid,
                recAreaGuid,
            })
        )
    );
};

export const onResetRecAreaPolygons = function* (
    action: ReturnType<typeof actions.resetRecAreaPolygons>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid } = action.payload;
    const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);

    const zonesIncludedInRec = recDetails.recAreaList.filter((recArea) => recArea.applyRecToArea);
    const recAreaId = Math.min(...zonesIncludedInRec.map((recArea) => recArea.recAreaId));

    yield put(recsEventsActions.setCurrentAreaId(fieldGuid, recAreaId));
};

export const onSaveRecDetails = function* (
    action: ReturnType<typeof actions.saveRecDetails>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const userGuid = yield select(getTheUserGuid);
    const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);
    const batchFieldGuid = (yield select(getZonesState)).batchFieldGuid;

    // Update the times strings to match the format expected for upload
    const recDetailsList = [...fieldGuidToRecDetails.values()].map((recDetails) =>
        models.RecDetails.updateRecDetails(recDetails, {
            recDate: `${recDetails.momentCreatedDate.format("YYYY-MM-DD")}T20:00:00.000Z`,
        })
    );

    const isUpdate = recDetailsList.some((rec) => rec.recGeneralGuid !== "");
    const fieldGuidToCountMap = new Map();
    if (!isUpdate) {
        const fieldMap = yield select(cdSelectors.getFieldMap);
        const fieldInfoList = recDetailsList
            .map((recDetails) => fieldMap.get(recDetails.fieldGuid))
            .filter((field) => field != null);
        for (const { fieldGuid, recCount } of fieldInfoList) {
            fieldGuidToCountMap.set(fieldGuid, recCount);
        }
    }
    const isEquation =
        recDetailsList[0].recType === models.REC_TYPE_NAME_EQUATION_APPLICATION ||
        recDetailsList[0].recType === models.REC_TYPE_NAME_EQUATION_PLANTING;

    const apiFunction = isEquation
        ? isUpdate
            ? RecAPI.updateRecsWithEquationRun
            : RecAPI.addRecsWithEquationRun
        : isUpdate
        ? RecAPI.updateRecs
        : RecAPI.addRecs;

    const filteredRecDetailsList = recDetailsList.filter(
        (recGeneral) => recGeneral.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID
    );
    const dirtyRecDetailsList = filteredRecDetailsList.filter((recDetails) =>
        recDetails.recAreaList.some((recArea) =>
            recArea.recs.some((rec) => !rec.isAnyRecAdjustmentProcessing && rec.isRecDirty)
        )
    );
    const unsavedRecDetailsList = filteredRecDetailsList.filter((recDetails) =>
        recDetails.recAreaList.some((recArea) =>
            recArea.recs.some(
                (rec) => rec.recGuid && !rec.isAnyRecAdjustmentProcessing && !rec.isRecDirty
            )
        )
    );
    let errorFieldGuids = [];
    try {
        if (!isEquation) {
            yield call(apiFunction, userGuid, filteredRecDetailsList);
        }
        if (isEquation && unsavedRecDetailsList.length !== 0) {
            yield call(RecAPI.updateRecs, userGuid, unsavedRecDetailsList);
        }
        if (dirtyRecDetailsList.length !== 0) {
            yield put(recInfoActions.setRecDetailsLoading(true));
            const newProps = {
                isEquationRun: true,
                isAnyEquationRun: true,
                isAnyRecAdjustmentProcessing: true,
                isFilterChanged: false,
            };
            const cleanedRecDetailsList = [];
            for (const recGeneral of dirtyRecDetailsList) {
                const { fieldGuid } = recGeneral;
                const newRecAreaList = [];
                for (const recArea of recGeneral.recAreaList) {
                    const newRecList = [];
                    for (const rec of recArea.recs) {
                        const recNutrientList = rec.recNutrientList.map((recNutrient) => {
                            return {
                                ...recNutrient,
                                productBlendGuid:
                                    recNutrient.recNutrientProductMix.productBlendGuid || null,
                                missingEquationAttributes: [],
                            };
                        });
                        yield put(
                            actions.updateRecModel(fieldGuid, recArea.recAreaId, false, {
                                ...newProps,
                                recNutrientList,
                            })
                        );
                        newRecList.push(
                            models.Rec.updateRec(rec, {
                                ...newProps,
                                recNutrientList,
                            })
                        );
                    }
                    newRecAreaList.push(
                        models.RecArea.updateRecArea(recArea, {
                            recs: newRecList,
                        })
                    );
                }
                const newRecDetails = models.RecDetails.updateRecDetails(recGeneral, {
                    recAreaList: newRecAreaList,
                });
                cleanedRecDetailsList.push(newRecDetails);
                if (fieldGuid === batchFieldGuid || filteredRecDetailsList.length === 1) {
                    const fields: SearchAPI.IFieldResult[] = yield call(SearchAPI.getFields, {
                        userGuid,
                        fieldGuid: [fieldGuid],
                    });
                    const field =
                        fields.length > 0 ? { ...fields[0], fieldGuid: fields[0].id } : null;
                    const toolsetPayload = {
                        recEventDetails: newRecDetails,
                        field,
                    };
                    yield put(setActiveToolsetPayloadOnly(toolsetPayload));
                }
            }

            const validationRequestList = [];
            for (const recGeneral of cleanedRecDetailsList) {
                if (recGeneral.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID) {
                    validationRequestList.push({
                        recNameToRun: ALL,
                        recommendationGeneral: {
                            ...recGeneral,
                        },
                    });
                }
            }
            const validationResponse = yield call(
                RecAPI.validateEquationRecs,
                userGuid,
                validationRequestList
            );
            errorFieldGuids = validationResponse
                .filter((resp) => !resp.success)
                .map((resp) => {
                    return resp.model.fieldGuid;
                });
            const isValid = errorFieldGuids.length === 0;
            const errorCodeList = validationResponse.reduce((errorCodeList, resp) => {
                resp.errorCodeList.forEach((err) => errorCodeList.add(err));
                return errorCodeList;
            }, new Set());

            if (!isValid) {
                const newProps = {
                    isEquationRun: false,
                    isAnyEquationRun: false,
                    isAnyRecAdjustmentProcessing: false,
                    isRecDirty: false,
                };

                for (const recGeneral of recDetailsList) {
                    const { fieldGuid } = recGeneral;
                    if (errorFieldGuids.includes(fieldGuid)) {
                        for (const recArea of recGeneral.recAreaList) {
                            yield put(
                                actions.updateRecModel(
                                    fieldGuid,
                                    recArea.recAreaId,
                                    false,
                                    newProps
                                )
                            );
                        }
                    }
                }
                yield put(actions.saveRecDetailsFailed(Array.from(errorCodeList), errorFieldGuids));
            }

            const requestObjects = cleanedRecDetailsList
                .filter(
                    (recGeneral) =>
                        recGeneral.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID &&
                        !errorFieldGuids.includes(recGeneral.fieldGuid)
                )
                .map((recGeneral) => {
                    return {
                        recNameToRun: ALL,
                        recommendationGeneral: {
                            ...recGeneral,
                        },
                    };
                });
            yield call(apiFunction, userGuid, requestObjects);
        }
    } catch (err) {
        if (!(err instanceof APIErrorWithCode)) {
            yield put(notificationActions.apiCallError(err, action));
        } else {
            const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);

            const recDetailsList = [...fieldGuidToRecDetails.values()];
            const isEquation =
                recDetailsList[0].recType === models.REC_TYPE_NAME_EQUATION_APPLICATION ||
                recDetailsList[0].recType === models.REC_TYPE_NAME_EQUATION_PLANTING;
            const newProps = !isEquation
                ? null
                : {
                      isEquationRun: false,
                      isAnyEquationRun: false,
                      isAnyRecAdjustmentProcessing: false,
                      isRecDirty: true,
                  };

            for (const recGeneral of recDetailsList) {
                const { fieldGuid } = recGeneral;
                errorFieldGuids.push(fieldGuid);
                if (newProps) {
                    for (const recArea of recGeneral.recAreaList) {
                        yield put(
                            actions.updateRecModel(fieldGuid, recArea.recAreaId, false, newProps)
                        );
                    }
                }
            }
            yield put(actions.saveRecDetailsFailed(err.errorCodeList, errorFieldGuids));
        }
    } finally {
        yield put(recInfoActions.setRecDetailsLoading(false));
    }
    if (!isUpdate) {
        const countUpdatePayload = [...fieldGuidToCountMap.entries()].map(
            ([fieldGuid, recCount]) => ({
                fieldGuid,
                recCount: errorFieldGuids.includes(fieldGuid) ? recCount : recCount + 1,
            })
        );
        yield put(cdActions.batchUpdateFieldRecCount(countUpdatePayload));
    }
    // saveRecDetailsSucceeded effectively means 'close the panel', so on a run-only for equations, we don't need it
    if (!isEquation || dirtyRecDetailsList.length === 0) {
        yield put(actions.saveRecDetailsSucceeded(false));
    }
};

export const fetchRecTypeInfo = function* (
    action: ReturnType<typeof actions.copyBatchTemplateToRecs>
): Generator<CallEffect | PutEffect<any>, void, any> {
    try {
        const allTypesResponse = yield call(RecAPI.getRecTypes);
        const recTypeInfoList = allTypesResponse.map((jsonObj) =>
            models.RecTypeInfo.fromGetRecTypeResponseObj(jsonObj)
        );
        yield put(actions.setRecTypeInfo(recTypeInfoList));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const onUpdateBatchRecDetails = function* (
    action: ReturnType<typeof actions.updateBatchRecDetails>
): Generator<PutEffect<any>, void, any> {
    const { recAreaList } = action.payload;
    const batchGuid = commonModels.BATCH_TEMPLATE_FIELD_GUID;
    yield put(
        actions.updateRecDetails(batchGuid, {
            recGeneralGuid: "BATCH_REC_GENERAL_GUID",
            recAreaList,
        })
    );
};

export const onUpdateCurrentRecAreaRec = function* (
    action: ReturnType<typeof actions.updateCurrentRecAreaRec>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, newProps } = action.payload;
    const { fieldGuidToCurrentAreaId } = yield select(getZonesState);
    const recAreaId = fieldGuidToCurrentAreaId.get(fieldGuid);
    yield put(actions.updateRecModel(fieldGuid, recAreaId, false, newProps));
};

export const onUpdateRecModel = function* (
    action: ReturnType<typeof actions.updateRecModel>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, areaId, newProps } = action.payload;
    yield put(actions.setRecModel(fieldGuid, areaId, newProps));
    const enableSave = yield select(selectors.getEnableSave, fieldGuid);
    const equationReady = yield select(selectors.getRecEquationRunReady, fieldGuid);
    //Recs seem to bounce back and forth, so lock in the batch only if they flip between ready to run and not.
    const batchBackgroundDisabled = enableSave || equationReady;
    yield put(setFieldsBackgroundOnlyBatch(!batchBackgroundDisabled));
};

export const onUpdatePlantingRecModel = function* (
    action: ReturnType<typeof actions.updatePlantingRecModel>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, newProps } = action.payload;
    const { fieldGuidToRecDetails } = yield select(selectors.getModuleState);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);
    recDetails.recAreaList.forEach((recArea) => {
        recArea.recs.forEach((rec) => {
            const { recPlanting } = rec;
            if (recPlanting.recPlantingVarietyHybridList.length) {
                recPlanting.recPlantingVarietyHybridList =
                    recPlanting.recPlantingVarietyHybridList.map((vh) => {
                        return { ...vh, ...newProps };
                    });
            }
        });
    });
    yield put(
        actions.updateRecDetails(fieldGuid, {
            recAreaList: recDetails.recAreaList,
        })
    );
};

export const recsSaga = function* (): Generator<AllEffect | PutEffect<any>, void, any> {
    yield all([
        takeEvery(actions.ACTIVATE_REC, onActivateRec),
        takeEvery(actions.ADD_DEFAULT_PRODUCT, onAddDefaultProduct),
        takeEvery(actions.ADD_REC_ADJUSTMENTS, onAddRecAdjustments),
        takeEvery(actions.ADD_UPDATE_REC_PRODUCT, onAddUpdateRecProduct),
        takeLatest(actions.COPY_BATCH_TEMPLATE_TO_RECS, onCopyBatchTemplateToRecs),
        takeLatest(actions.COPY_REC_DETAILS, onCopyRecDetails),
        takeEvery(actions.CREATE_NEW_BATCH_REC, onCreateNewBatchRec),
        takeEvery(actions.CREATE_NEW_CLASSIFIED_REC_DETAILS, onCreateNewClassifiedRec),
        takeEvery(actions.CREATE_NEW_REC_DETAILS, onCreateNewRecDetails),
        takeEvery(actions.DELETE_RECS, onDeleteRecs),
        takeLatest(actions.FETCH_BATCH_REC_DETAILS, onFetchBatchRecDetails),
        takeLatest(actions.FETCH_REC_DETAILS, onFetchRecDetails),
        takeEvery(actions.FETCH_REC_DETAILS_SUCCEEDED, onFetchRecDetailsSucceeded),
        takeLatest(
            actions.FETCH_REC_INTEGRATION_PARTNER_ACCESS,
            onFetchRecIntegrationPartnerAccess
        ),
        takeLatest(actions.INITIALIZE_RECS_ZONES, onInitializeRecsZones),
        takeLatest(actions.INITIALIZE_RECS_ZONES_SUCCEEDED, onInitializeRecsZonesSucceeded),
        takeEvery(actions.RESET_CURRENT_REC_AREA_REC, onResetCurrentRecAreaRec),
        takeEvery(
            [actions.RESET_REC_AREA_POLYGONS, actions.RESET_CLASSIFIED_REC_AREA_POLYGONS],
            onResetRecAreaPolygons
        ),
        takeLatest(actions.SAVE_REC_DETAILS, onSaveRecDetails),
        takeEvery(actions.SHOW_BATCH_RECS_EDIT, onShowBatchRecsEdit),
        takeEvery(loginActions.SET_USER_INFO_COMPLETE, fetchRecTypeInfo),
        takeEvery(blendingActions.UPDATE_BATCH_REC_DETAILS, onUpdateBatchRecDetails),
        takeEvery(actions.UPDATE_CURRENT_REC_AREA_REC, onUpdateCurrentRecAreaRec),
        takeEvery(actions.UPDATE_REC_MODEL, onUpdateRecModel),
        takeEvery(actions.UPDATE_PLANTING_REC_MODEL, onUpdatePlantingRecModel),
        takeEvery(recInfoActions.UPDATE_REC_SEASON_DEPENDENCIES, onUpdateRecSeasonDependencies),
    ]);
};
