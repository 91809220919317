import React, { useState } from "react";
import { messages } from "./i18n-messages";
import { DialogBox, DialogBoxFooterType, Loader } from "~/core";
import { useAppSelector } from "~/store/hooks";
import {
    getFieldInfoState,
    getIsAddOrIsUnarchive,
    getisAddOrUnarchiveBoundaryInPortalModalOpen,
} from "~/action-panel/components/field-module/selectors";
import { getToolsetPayload } from "~/map/components/map-tools/selectors";
import { getFieldEditGeometries } from "~/map/components/map-tools/selectors";
import { injectIntl, intlShape } from "react-intl";
import { useDispatch } from "react-redux";
import { actions as fieldActions } from "~/action-panel/components/field-module";
import { RecAPI } from "@ai360/core";
import { getUser } from "~/login/selectors";
import { MSGTYPE, actions as notificationActions } from "~/notifications";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import { getTheUserCompanyGuid } from "~/login";
import "./add-boundary-to-portal.css";

function AddBoundaryToPortal({ intl: { formatMessage } }) {
    const isOpen = useAppSelector(getisAddOrUnarchiveBoundaryInPortalModalOpen);
    const isAddOrIsUnarchive = useAppSelector(getIsAddOrIsUnarchive);
    const fieldInfo = useAppSelector(getFieldInfoState);

    // name and GUID on our end (for field adds)
    const fieldName = fieldInfo.fieldModel.fieldName;
    const fieldGuid = fieldInfo.fieldModel.fieldGuid;

    // name and ID on the JD Ops Center end (for field unarchives)
    const fieldNameInJd = useAppSelector(getFieldInfoState).fieldName;
    const fieldIdInJd = useAppSelector(getFieldInfoState).fieldId;

    const userGuid = useAppSelector(getUser).userGuid;
    const companyGuid = useAppSelector(getTheUserCompanyGuid);
    const customerGuid = useAppSelector(getToolsetPayload).customerGuid;
    const geometries = useAppSelector(getFieldEditGeometries);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const dispatch = useDispatch();

    const addOrUnarchiveBoundary = async () => {
        setShowLoader(true);
        const geometriesForAdd = geometries.map((x) => webMercatorUtils.webMercatorToGeographic(x));
        const request = {
            geometries: JSON.stringify(geometriesForAdd),
            fieldName: isAddOrIsUnarchive === "ADD" ? fieldName : fieldNameInJd,
            fieldId: fieldIdInJd,
            fieldGuid: fieldGuid,
            isIrrigated: false,
            isAdd: isAddOrIsUnarchive === "ADD",
        };
        const response = await RecAPI.addBoundaryToJdOpsCenter(
            request,
            companyGuid,
            customerGuid,
            userGuid
        );
        if (!response.success) {
            dispatch(notificationActions.pushToasterMessage(response.message, MSGTYPE.ERROR));
        } else {
            dispatch(notificationActions.pushToasterMessage(response.message, MSGTYPE.SUCCESS));
        }

        setShowLoader(false);
        dispatch(fieldActions.setAddOrUnarchiveBoundaryInPortalModalOpen(false));
    };

    return (
        <DialogBox
            draggable={true}
            isOpen={isOpen}
            unrestricted={true}
            footerType={DialogBoxFooterType.YES_NO}
            title={
                isAddOrIsUnarchive === "ADD"
                    ? formatMessage(messages.titleForAdd)
                    : formatMessage(messages.titleForUnarchive)
            }
            onAction={() => {
                addOrUnarchiveBoundary();
            }}
            onClose={() => {
                dispatch(fieldActions.setAddOrUnarchiveBoundaryInPortalModalOpen(false));
            }}
        >
            {showLoader && <Loader />}
            {isAddOrIsUnarchive === "ADD" ? (
                <div className="add-or-update-dialog-message">
                    <div className="add-or-update-line">
                        {formatMessage(messages.fieldHasNoConflictsOrMatches)}
                    </div>
                    <div className="add-or-update-line">
                        {formatMessage(messages.wantToAddField)}
                    </div>
                </div>
            ) : (
                <div className="add-or-update-dialog-message">
                    <div className="add-or-update-line">
                        {formatMessage(messages.fieldHasNoConflictsOrMatchesButIsArchived)}
                    </div>
                    <div className="add-or-update-line">
                        {formatMessage(messages.wantToUnarchiveField)}
                    </div>
                </div>
            )}
        </DialogBox>
    );
}

AddBoundaryToPortal.propTypes = {
    intl: intlShape,
};

export default injectIntl(AddBoundaryToPortal);
