import { createSelector } from "reselect";

import { ACTION_PANEL_STATE_KEY } from "../../selectors";
import { FieldListTabs } from "./actions";
import { AgvanceAPI, FieldAPI } from "@ai360/core";
import * as models from "./models";
import RootState from "~/types/store";
import { FieldBoundaryOverlapAction } from "./interfaces";
import { Polygon } from "@arcgis/core/geometry";

export const FIELD_INFO_STATE_KEY = "FIELD_INFO_STATE";
export const FIELD_MODULE_STATE_KEY = "FIELD_MODULE_STATE";

export const getModuleState = (state: RootState): any =>
    state[ACTION_PANEL_STATE_KEY][FIELD_MODULE_STATE_KEY];
export const getFieldInfoState = (state: RootState): any =>
    getModuleState(state)[FIELD_INFO_STATE_KEY];

export const getAgvanceFieldData = (state: RootState): any =>
    getFieldInfoState(state).agvanceFieldData;
export const getActiveTab = (state: RootState): FieldListTabs => getFieldInfoState(state).activeTab;
export const getAgvanceFieldList = (state: RootState): AgvanceAPI.IFieldResult[] =>
    getFieldInfoState(state).agvanceFieldList;
export const getAgvanceFieldClassificationList = (state: RootState): unknown =>
    getFieldInfoState(state).agvanceFieldClassificationList;
export const getAgvanceOrgLevelList = (state: RootState): unknown =>
    getFieldInfoState(state).agvanceOrgLevelList;
export const getCustomerFieldListWithOrgLevel = (state: RootState): unknown =>
    getFieldInfoState(state).customerFieldListWithOrgLevel;
export const getErrorCodes = (state: RootState): unknown => getFieldInfoState(state).errorCodes;
export const getFetchingFieldInfo = (state: RootState): unknown =>
    getFieldInfoState(state).fetchingFieldInfo;
export const getFieldInfoModel = (state: RootState): models.IFieldModel => ({
    ...getFieldInfoState(state).fieldModel,
});
export const getFieldDeleteAgvanceOverrideFieldGuids = (state: RootState): string[] =>
    getFieldInfoState(state).fieldDeleteAgvanceOverride?.fieldGuids;
export const getFieldModuleDropdowns = (state: RootState): any =>
    getFieldInfoState(state).dropdowns;
export const getIsFieldLoading = (state: RootState): any => getFieldInfoState(state).isLoading;
export const getIsNewField = (state: RootState): any => getFieldInfoState(state).isNewField;
export const getIsFieldInit = (state: RootState): any => getFieldInfoState(state).fieldInit;
export const getLastSearchValue = (state: RootState): any =>
    getFieldInfoState(state).lastSearchValue;
export const getFieldBoundaryOverlapAction = (state: RootState): FieldBoundaryOverlapAction =>
    getFieldInfoState(state).fieldBoundaryOverlapAction;
export const getFieldsToMove = (state: RootState): any => getFieldInfoState(state).fieldsToMove;
export const getMoveDialogLoading = (state: RootState): any =>
    getFieldInfoState(state).isMoveDialogLoading;
export const getFullFieldRecords = (state: RootState): FieldAPI.IFieldResult[] =>
    getFieldInfoState(state).fullFieldRecords;
export const getIsCombineDialogOpen = (state: RootState): any =>
    getFieldInfoState(state).isCombineDialogOpen;
export const getIsFieldBoundaryOverlapOpen = (state: RootState): any =>
    getFieldInfoState(state).isFieldBoundaryOverlapOpen;
export const getIsLockedBoundaryWarningOpen = (state: RootState): any =>
    getFieldInfoState(state).isLockedBoundaryWarningOpen;
export const getIsPortalBoundarySyncModalOpen = (state: RootState): any =>
    getFieldInfoState(state).isPortalBoundarySyncModalOpen;
export const getisAddOrUnarchiveBoundaryInPortalModalOpen = (state: RootState): any =>
    getFieldInfoState(state).isAddOrUnarchiveBoundaryInPortalModalOpen;
export const getIsAddOrIsUnarchive = (state: RootState): any =>
    getFieldInfoState(state).isAddOrIsUnarchive;
export const getMapDetailsForPortalBoundarySyncModal = (
    state: RootState
): {
    latitude: number;
    longitude: number;
    zoomLevel: number;
    fieldPolygons: Polygon[];
} => {
    return {
        latitude: getFieldInfoState(state).latitude,
        longitude: getFieldInfoState(state).longitude,
        zoomLevel: getFieldInfoState(state).zoomLevel,
        fieldPolygons: getFieldInfoState(state).fieldPolygons,
    };
};
export const getJdBoundaryOverlapsForPortalBoundarySyncModal = (
    state: RootState
): {
    [x: string]: any;
    overlaps: any[];
} => {
    return {
        overlaps: getFieldInfoState(state).overlaps,
    };
};
export const getIsFieldBoundaryTransferOpen = (state: RootState): any =>
    getFieldInfoState(state).isFieldBoundaryTransferOpen;
export const getOrgLevelGuid = (state: RootState): any => getFieldInfoState(state).orgLevelGuid;
export const getResurfaceEvents = (state: RootState): any =>
    getFieldInfoState(state).resurfaceEvents;
export const getActiveTabIsOperationTab = createSelector(
    getActiveTab,
    (activeTab) => activeTab === FieldListTabs.ACTIVE || activeTab === FieldListTabs.SELECTED
);
export const getCombineFieldsError = (state: RootState): unknown =>
    getFieldInfoState(state).combineFieldsError;
export const getFieldBoundaryTransferFieldModel = (state: RootState) =>
    getFieldInfoState(state).fieldBoundaryTransferFieldModel;
export const getFieldBoundaryTransferGeometries = (state: RootState): __esri.Polygon[] =>
    getFieldInfoState(state).fieldBoundaryTransferGeometries;
export const getOverlappingFieldBoundaries = (
    state: RootState
): { [fieldGuid: string]: FieldAPI.IFieldBoundary[] } =>
    getFieldInfoState(state).fieldBoundariesToClip;
