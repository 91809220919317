import * as model from "./model";
import { createService } from "~/hocs/crud";
import {
    PICKLIST_MORPHOLOGY_TYPE,
    PICKLIST_LIFESPAN,
    getPickListCode,
    PICKLIST_INTENDED_USE,
    PICKLIST_COMMODITY,
    PICKLIST_COMMODITY_TYPE,
    PICKLIST_CROPPING_PRACTICE,
} from "~/core/picklist/picklist-names";
import { apiUrl, aggregateUrlGenerator } from "@ai360/core";

const AGGREGATE_NAME = "crop";
const ENDPOINT_NAME = "agbytes/crop";
const API_VERSION = "v1.0";

const aggregateUrl = aggregateUrlGenerator(ENDPOINT_NAME, API_VERSION);

export const MODEL_NAME = AGGREGATE_NAME;
export const EXPORT_FILE_NAME = "CropExport";
export const URL = aggregateUrl("list");
export const GETRECORD = aggregateUrl();
export const NEXT_ID = aggregateUrl("nextId");
export const CREATE = aggregateUrl();
export const UPDATE = aggregateUrl();
export const DELETE = aggregateUrl();
export const EXPORT_URL = aggregateUrl("export");
export const AUTO_SEARCH_URL = aggregateUrl("autoComplete");
export const IMPORT_URL = aggregateUrl("import");
export const IMPORT_VALID_URL = aggregateUrl("validateImport");
export const SELECT_ALL = aggregateUrl("selectAll");

// Request payload
export const REQUEST_PAYLOAD_FILTER = "cropFilter";
export const REQUEST_PAYLOAD_SORT_LIST = "cropSortList";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "cropPageOptions";
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";

// Dropdowns
export const REQUEST_GROWTH_STAGE = apiUrl("AgBytes/GetGrowthStageAgBytesList");
export const REQUEST_TISSUE_GROWTH_STAGE = apiUrl("AgBytes/GetTissueGrowthStageAgBytesList");

export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        cropId: "",
        cropParent: "",
        cropName: "",
        cropClassName: "",
        groupName: "",
        tissueGroupName: "",
        lifespan: "",
        modifiedDate: "",
        canDelete: "",
        activeYn: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "CropParent",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const defaultCrop = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_AGGATEWAY_ID]: "",
    [model.PROPS_AGRIAN_ID]: [],
    [model.PROPS_AGX_ID]: "",
    [model.PROPS_CAN_DELETE]: "",
    [model.PROPS_CROP_ALIAS]: [],
    [model.PROPS_CROP_CLASS_ID]: "",
    [model.PROPS_CROP_CLASS_NAME]: "",
    [model.PROPS_CROP_GUID]: "",
    [model.PROPS_CROP_ID]: "",
    [model.PROPS_CROP_NAME]: "",
    [model.PROPS_CROP_GROUP]: "",
    [model.PROPS_CROP_PHOTO_LIST]: [],
    [model.PROPS_DEFAULT_MAP_COLOR]: "#000000",
    [model.PROPS_DIVISION]: "",
    [model.PROPS_FAMILY]: "",
    [model.PROPS_FARM_MARKET_CROP_CODE]: "",
    [model.PROPS_FCICCROP_CODE]: "",
    [model.PROPS_GENUS]: "",
    [model.PROPS_GROUP_NAME]: "",
    [model.PROPS_GROWTH_STAGE_GROUP_GUID]: null,
    [model.PROPS_GROWTH_STAGE_GROUPID]: "",
    [model.PROPS_GROWTH_STAGE_GROUP_NAME]: "",
    [model.PROPS_LIFESPAN]: "",
    [model.PROPS_LIFESPAN_GUID]: null,
    [model.PROPS_MODIFIED_DATE]: "",
    [model.PROPS_MORPHOLOGY_TYPE]: "",
    [model.PROPS_MORPHOLOGY_TYPE_GUID]: null,
    [model.PROPS_OPTIMAL_MOISTURE_AT_HARVEST]: 0,
    [model.PROPS_PLANTING_TEMPERATURE_MINIMUM]: 0,
    [model.PROPS_SCIENTIFIC_NAME]: "",
    [model.PROPS_TAXONOMICAL_CLASS]: "",
    [model.PROPS_TAXONOMICAL_ORDER]: "",
    [model.PROPS_TEST_WEIGHT]: 0,
    [model.PROPS_TISSUE_GROWTH_STAGE_GROUPID]: "",
    [model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME]: "",
    [model.PROPS_TISSUE_GROWTH_STAGE_GROUP_GUID]: null,
});

export const pickLists = {
    [PICKLIST_MORPHOLOGY_TYPE]: getPickListCode(PICKLIST_MORPHOLOGY_TYPE),
    [PICKLIST_LIFESPAN]: getPickListCode(PICKLIST_LIFESPAN),
    [PICKLIST_INTENDED_USE]: getPickListCode(PICKLIST_INTENDED_USE),
    [PICKLIST_COMMODITY]: getPickListCode(PICKLIST_COMMODITY),
    [PICKLIST_COMMODITY_TYPE]: getPickListCode(PICKLIST_COMMODITY_TYPE),
    [PICKLIST_CROPPING_PRACTICE]: getPickListCode(PICKLIST_CROPPING_PRACTICE),
};

export const dropdowns = {
    [model.PROPS_GROWTH_STAGE_GROUP_NAME]: REQUEST_GROWTH_STAGE,
    [model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME]: REQUEST_TISSUE_GROWTH_STAGE,
};

export const service = createService({
    id: model.PROPS_CROP_ID,
    guid: model.PROPS_CROP_GUID,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    EXPORT_FILE_NAME,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    pickLists,
    dropdowns,
    activeColumnName: model.PROPS_ACTIVE_YN,
    urls: {
        URL,
        NEXT_ID,
        EXPORT_URL,
        IMPORT_URL,
        IMPORT_VALID_URL,
        AUTO_SEARCH_URL,
        SELECT_ALL,
        CREATE,
        UPDATE,
        DELETE,
        GETRECORD,
    },
    _defaultLabels: {
        [model.PROPS_CROP_ID]: { label: "cropId", gridCol: 5 },
        [model.PROPS_CROP_GROUP]: { label: "cropGroup", gridCol: 10 },
        [model.PROPS_CROP_NAME]: { label: "cropName", gridCol: 10 },
        [model.PROPS_CROP_CLASS_NAME]: { label: "cropClassName", gridCol: 10 },
        [model.PROPS_GROWTH_STAGE_GROUP_NAME]: {
            label: "growthStageGroupName",
            gridCol: 10,
        },
        [model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME]: {
            label: "tissueGrowthStageGroupName",
            gridCol: 10,
        },
        [model.PROPS_LIFESPAN]: { label: "lifespan", gridCol: 10 },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
    },
    getDefaultRecord: () => Object.assign({}, defaultCrop()),
    defaultSort,
    useNewApi: true,
    transformGetResponse: async (response) => {
        const base64Photos = await Promise.all(
            response.photos.map(async (x) => {
                const response = await fetch(x.fullImagePresignedUrl);
                const base64 = await response.text();
                return {
                    id: x.id,
                    base64,
                };
            })
        );

        return {
            ...response,
            photos: response.photos.map((x) => ({
                ...x,
                fullImageBase64: base64Photos.find((y) => y.id === x.id).base64,
            })),
        };
    },
});
