import { defineMessages } from "react-intl";

export const messages = defineMessages({
    title: {
        id: "portalBoundaryUpdate.title",
        defaultMessage: "Portal Boundary Update - JD Operations Center - {farm}{field}",
    },
    cancel: { id: "portalBoundaryUpdate.cancel", defaultMessage: "Cancel" },
    fieldHasMatch: {
        id: "portalBoundaryUpdate.fieldHasMatch",
        defaultMessage:
            "This Field has an exact boundary match to a Field named {jdFieldName} in JD Ops Center",
    },
    update: { id: "portalBoundaryUpdate.update", defaultMessage: "Update" },
    updateAi360Boundary: {
        id: "portalBoundaryUpdate.updateAi360Boundary",
        defaultMessage: "Update {system} boundary using JD Ops Center boundary",
    },
    updateAi360BoundarySucceeded: {
        id: "portalBoundaryUpdate.updateAi360BoundarySucceeded",
        defaultMessage: "Updated boundary to match the JD Ops Center boundary",
    },
    updateFailed: {
        id: "portalBoundaryUpdate.updateFailed",
        defaultMessage: "Unable to update the boundary in the JD Ops Center",
    },
    updateJdOpsCenterBoundary: {
        id: "portalBoundaryUpdate.updateJdOpsCenterBoundary",
        defaultMessage: "Update JD Ops Center boundary using {system} boundary",
    },
    updateJdBoundarySucceeded: {
        id: "portalBoundaryUpdate.updateSucceeded",
        defaultMessage: "Updated boundary in the JD Ops Center",
    },
});
