import { defineMessages } from "react-intl";

export const messages = defineMessages({
    titleForAdd: {
        id: "addBoundaryToPortal.titleForAdd",
        defaultMessage: "Add Field to JD Ops Center",
    },
    titleForUnarchive: {
        id: "addBoundaryToPortal.titleForUnarchive",
        defaultMessage: "Unarchive Field in JD Ops Center",
    },
    fieldHasNoConflictsOrMatches: {
        id: "addBoundaryToPortal.fieldHasNoConflicts",
        defaultMessage: "No overlapping Fields detected for same Customer in the JD Ops Center.",
    },
    fieldHasNoConflictsOrMatchesButIsArchived: {
        id: "addBoundaryToPortal.fieldHasNoConflictsButIsArchived",
        defaultMessage: "This Field already exists in the JD Ops Center, but is archived.",
    },
    wantToAddField: {
        id: "addBoundaryToPortal.wantToAddField",
        defaultMessage: "Would you like to add this Field to JD Ops Center?",
    },
    wantToUnarchiveField: {
        id: "addBoundaryToPortal.wantToUnarchiveField",
        defaultMessage: "Would you like to unarchive it?",
    },
});
