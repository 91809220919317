import React, { Component } from "react";
import { service } from "./../data";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { messages } from "./../../i18n-messages";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
// components
import {
    Checkbox,
    ColorPicker,
    NumericInput,
    Section,
    SelectInput,
    SubSection,
    TextInput,
} from "~/core";
import { ISelectOption } from "@ai360/core";
import AgrianId from "./../../components/agrian-id-list";
import AliasList from "./../../components/alias-list";
import PhotoList from "./../../components/photo-list";
import CommodityList from "~/admin/agBytes/components/commodity-list";
// constants
import {
    PICKLIST_MORPHOLOGY_TYPE,
    PICKLIST_LIFESPAN,
    PICKLIST_COMMODITY,
    PICKLIST_COMMODITY_TYPE,
    PICKLIST_INTENDED_USE,
    PICKLIST_CROPPING_PRACTICE,
} from "~/core/picklist/picklist-names";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import { model } from "./../data";
// types
import { IAddEditProps, Crop } from "../interfaces";

interface IAddEditState {
    nextId: number | null;
    [PICKLIST_LIFESPAN]: ISelectOption<any>[];
    [PICKLIST_MORPHOLOGY_TYPE]: ISelectOption<any>[];
    [PICKLIST_COMMODITY]: ISelectOption<any>[];
    [PICKLIST_COMMODITY_TYPE]: ISelectOption<any>[];
    [PICKLIST_INTENDED_USE]: ISelectOption<any>[];
    [PICKLIST_CROPPING_PRACTICE]: ISelectOption<any>[];
    [model.PROPS_GROWTH_STAGE_GROUP_NAME]: ISelectOption<any>[];
    [model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME]: ISelectOption<any>[];
}
class AddEditPanel extends Component<IAddEditProps & InjectedIntlProps, IAddEditState> {
    static DEFAULT_CROP_CLASS_ID = 1;
    crop: Crop;

    constructor(props) {
        super(props);
        this.state = {
            [PICKLIST_LIFESPAN]: [],
            [PICKLIST_MORPHOLOGY_TYPE]: [],
            [PICKLIST_COMMODITY]: prepareSelectableOptions(props[PICKLIST_COMMODITY], {
                guid: PICKLIST_GUID as any,
                label: VALUE,
                id: ID,
            }),
            [PICKLIST_COMMODITY_TYPE]: prepareSelectableOptions(props[PICKLIST_COMMODITY_TYPE], {
                guid: PICKLIST_GUID as any,
                label: VALUE,
                id: ID,
            }),
            [PICKLIST_INTENDED_USE]: prepareSelectableOptions(props[PICKLIST_INTENDED_USE], {
                guid: PICKLIST_GUID as any,
                label: VALUE,
                id: ID,
            }),
            [PICKLIST_CROPPING_PRACTICE]: prepareSelectableOptions(
                props[PICKLIST_CROPPING_PRACTICE],
                {
                    guid: PICKLIST_GUID as any,
                    label: VALUE,
                    id: ID,
                }
            ),
            [model.PROPS_GROWTH_STAGE_GROUP_NAME]: [],
            [model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME]: [],
            nextId: null,
        };
        this.crop = props.record || {};
    }

    componentDidMount() {
        this.setupCropRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([
            this.props.getNextId(),
            this.props.fetchPicklistData(),
            this.props.fetchDropdownData(),
        ]);
        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    setupCropRecord = () => {
        if (this.props.mode === "ADD") {
            this.crop = service.getDefaultRecord();
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.crop);
        }

        if (nextProps.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.crop = nextProps.record;
            }
        }
        this.initializeDropdowns(nextProps);
    }

    initializeDropdowns(nextProps) {
        this.initializeMorphologyType(nextProps);
        this.initializeLifespan(nextProps);
        this.initializeGrowthStages(nextProps);
        this.initializeTissueGrowthStages(nextProps);
        this.initializeMyAgData(nextProps);
    }

    initializeMorphologyType = (nextProps) => {
        if (nextProps[PICKLIST_MORPHOLOGY_TYPE]) {
            this.setState({
                [PICKLIST_MORPHOLOGY_TYPE]: prepareSelectableOptions(
                    nextProps[PICKLIST_MORPHOLOGY_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    initializeLifespan = (nextProps) => {
        if (nextProps[PICKLIST_LIFESPAN]) {
            this.setState({
                [PICKLIST_LIFESPAN]: prepareSelectableOptions(nextProps[PICKLIST_LIFESPAN], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                    id: ID,
                }),
            });
        }
    };

    initializeGrowthStages = (nextProps) => {
        if (nextProps[model.PROPS_GROWTH_STAGE_GROUP_NAME]) {
            this.setState({
                [model.PROPS_GROWTH_STAGE_GROUP_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_GROWTH_STAGE_GROUP_NAME],
                    { guid: GUID, label: NAME, id: ID, appendIdToLabel: true }
                ),
            });
        }
    };

    initializeTissueGrowthStages = (nextProps) => {
        if (nextProps[model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME]) {
            this.setState({
                [model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME],
                    {
                        guid: GUID,
                        label: NAME,
                        id: ID,
                        appendIdToLabel: true,
                    }
                ),
            });
        }
    };

    initializeMyAgData = (nextProps) => {
        if (nextProps[PICKLIST_COMMODITY] !== this.props[PICKLIST_COMMODITY]) {
            this.setState({
                [PICKLIST_COMMODITY]: prepareSelectableOptions(nextProps[PICKLIST_COMMODITY], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                    id: ID,
                }),
            });
        }

        if (nextProps[PICKLIST_COMMODITY_TYPE] !== this.props[PICKLIST_COMMODITY_TYPE]) {
            this.setState({
                [PICKLIST_COMMODITY_TYPE]: prepareSelectableOptions(
                    nextProps[PICKLIST_COMMODITY_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }

        if (nextProps[PICKLIST_INTENDED_USE] !== this.props[PICKLIST_INTENDED_USE]) {
            this.setState({
                [PICKLIST_INTENDED_USE]: prepareSelectableOptions(
                    nextProps[PICKLIST_INTENDED_USE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }

        if (nextProps[PICKLIST_CROPPING_PRACTICE] !== this.props[PICKLIST_CROPPING_PRACTICE]) {
            this.setState({
                [PICKLIST_CROPPING_PRACTICE]: prepareSelectableOptions(
                    nextProps[PICKLIST_CROPPING_PRACTICE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    setupNextId = (nextProps) => {
        if (nextProps.nextId) {
            if (
                this.crop[model.PROPS_CROP_ID] === service.getDefaultRecord()[model.PROPS_CROP_ID]
            ) {
                onNumberChange(model.PROPS_CROP_ID, nextProps.nextId);
            }

            if (
                this.crop[model.PROPS_CROP_CLASS_ID] ===
                service.getDefaultRecord()[model.PROPS_CROP_CLASS_ID]
            ) {
                onNumberChange(model.PROPS_CROP_CLASS_ID, {
                    formKey: model.PROPS_CROP_CLASS_ID,
                    value: AddEditPanel.DEFAULT_CROP_CLASS_ID,
                });
            }

            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    onNumberChange = (formKey, value, callback?: () => void) => {
        this.crop = onNumberChange(this.crop, { formKey: [formKey], value }, callback);
    };

    onTextChange = (formKey, value, callback?) => {
        if (formKey === model.PROPS_AGRIAN_ID || formKey === model.PROPS_CROP_ALIAS) {
            const valueArr = [];
            value.forEach((val) => {
                for (const prop in val) {
                    valueArr.push(val[prop]);
                }
            });
            this.crop = onTextChange(this.crop, { formKey: [formKey], value: valueArr }, callback);
        } else {
            this.crop = onTextChange(this.crop, { formKey: [formKey], value }, callback);
        }
    };

    onPicklistChange = ({ type, guid }, value, callback?) => {
        this.crop = handlePicklistChange(this.crop, { type, guid, value }, callback, true);
    };

    private onChangeMyAgData = (formKey, value) => {
        this.crop[model.PROPS_MYAGDATA_COMMODITY] = value;
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.crop[model.PROPS_CROP_NAME]]);
    };

    renderCropInfo = () => {
        const { apiErrors, userRole } = this.props;
        const { nextId } = this.state;
        const { crop } = this;
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={9}
                            suppressFormatting
                            containerClassNames={["form-input-id"]}
                            placeholderText={formatMessage(messages.cropId)}
                            value={nextId || crop[model.PROPS_CROP_ID]}
                            onChange={(value) => this.onNumberChange(model.PROPS_CROP_ID, value)}
                            required
                        />
                        {!userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <SubSection>
                                <Checkbox
                                    className="active-checkbox"
                                    onChange={(e, value) =>
                                        this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                    }
                                    value={crop[model.PROPS_ACTIVE_YN]}
                                    label={formatMessage(messages.active)}
                                />
                            </SubSection>
                        )}
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            containerClassNames={getAgBytesErrorClassNames(240, apiErrors)}
                            autoFocus
                            placeholderText={formatMessage(messages.cropGroup)}
                            value={crop[model.PROPS_CROP_GROUP] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_CROP_GROUP, value)}
                            required
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.cropName)}
                            containerClassNames={getAgBytesErrorClassNames(140, apiErrors)}
                            value={crop[model.PROPS_CROP_NAME] || ""}
                            onChange={(value) => {
                                this.onTextChange(model.PROPS_CROP_NAME, value, this.setHeaderText);
                            }}
                            required
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.cropClassId)}
                            labelText={formatMessage(messages.cropClassId)}
                            value={
                                crop[model.PROPS_CROP_CLASS_ID] ||
                                AddEditPanel.DEFAULT_CROP_CLASS_ID
                            }
                            precision={9}
                            scale={0}
                            allowNegatives={false}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_CROP_CLASS_ID, value)
                            }
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.cropClassName)}
                            value={crop[model.PROPS_CROP_CLASS_NAME] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_CROP_CLASS_NAME, value)
                            }
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section>
                    <SubSection className="form-section-row-2">
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_GROWTH_STAGE_GROUP_NAME]}
                            placeholderText={formatMessage(messages.growthStageGroupName)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_GROWTH_STAGE_GROUP_NAME],
                                selectedGuid: crop[model.PROPS_GROWTH_STAGE_GROUP_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_GROWTH_STAGE_GROUP_NAME,
                                        guid: model.PROPS_GROWTH_STAGE_GROUP_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME]}
                            placeholderText={formatMessage(messages.tissueGrowthStageGroupName)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME],
                                selectedGuid: crop[model.PROPS_TISSUE_GROWTH_STAGE_GROUP_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_TISSUE_GROWTH_STAGE_GROUP_NAME,
                                        guid: model.PROPS_TISSUE_GROWTH_STAGE_GROUP_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section>
                    <SubSection className="form-section-row-2">
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[PICKLIST_MORPHOLOGY_TYPE]}
                            placeholderText={formatMessage(messages.morphologyType)}
                            value={mapToPicklistValue({
                                options: this.state[PICKLIST_MORPHOLOGY_TYPE],
                                selectedGuid: crop[model.PROPS_MORPHOLOGY_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_MORPHOLOGY_TYPE,
                                        guid: model.PROPS_MORPHOLOGY_TYPE_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[PICKLIST_LIFESPAN]}
                            placeholderText={formatMessage(messages.lifespan)}
                            value={mapToPicklistValue({
                                options: this.state[PICKLIST_LIFESPAN],
                                selectedGuid: crop[model.PROPS_LIFESPAN_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_LIFESPAN,
                                        guid: model.PROPS_LIFESPAN_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    renderDetailInfo1() {
        const { crop } = this;
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column">
                <Section headerText={formatMessage(messages.taxonomicInfo)}>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.scientificName)}
                            value={crop[model.PROPS_SCIENTIFIC_NAME] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_SCIENTIFIC_NAME, value)
                            }
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.genus)}
                            value={crop[model.PROPS_GENUS] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_GENUS, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.family)}
                            value={crop[model.PROPS_FAMILY] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_FAMILY, value)}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.order)}
                            value={crop[model.PROPS_TAXONOMICAL_ORDER] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_TAXONOMICAL_ORDER, value)
                            }
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={100}
                            placeholderText={formatMessage(messages.class)}
                            value={crop[model.PROPS_TAXONOMICAL_CLASS] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_TAXONOMICAL_CLASS, value)
                            }
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={100}
                            placeholderText={formatMessage(messages.division)}
                            value={crop[model.PROPS_DIVISION] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_DIVISION, value)}
                        />
                    </SubSection>
                </Section>
                <Section headerText={formatMessage(messages.crossReference)}>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.agGatewayID)}
                            value={crop[model.PROPS_AGGATEWAY_ID] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_AGGATEWAY_ID, value)}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.agXID)}
                            value={crop[model.PROPS_AGX_ID] || ""}
                            onChange={(value) => this.onTextChange(model.PROPS_AGX_ID, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            placeholderText={formatMessage(messages.fcicCropCode)}
                            value={crop[model.PROPS_FCICCROP_CODE] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_FCICCROP_CODE, value)
                            }
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={20}
                            placeholderText={formatMessage(messages.farmMarketCropCode)}
                            value={crop[model.PROPS_FARM_MARKET_CROP_CODE] || ""}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_FARM_MARKET_CROP_CODE, value)
                            }
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }

    renderDetailInfo2() {
        const { crop } = this;
        const { formatMessage } = this.props.intl;
        const { commodity, commodityType, intendedUse, croppingPractice } = this.state;
        return (
            <div className="section-container section-column">
                <Section headerText={formatMessage(messages.plantHarvestInfo)}>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.plantingTemperatureMinimum)}
                            labelText={formatMessage(messages.plantingTemperatureMinimum)}
                            value={crop[model.PROPS_PLANTING_TEMPERATURE_MINIMUM] || ""}
                            precision={5}
                            scale={2}
                            allowNegatives={true}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_PLANTING_TEMPERATURE_MINIMUM, value)
                            }
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.testWeight)}
                            labelText={formatMessage(messages.testWeight)}
                            value={crop[model.PROPS_TEST_WEIGHT] || ""}
                            precision={10}
                            scale={4}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_TEST_WEIGHT, value)
                            }
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.optionalMoistureAtHarvest)}
                            labelText={formatMessage(messages.optionalMoistureAtHarvest)}
                            value={crop[model.PROPS_OPTIMAL_MOISTURE_AT_HARVEST] || ""}
                            precision={18}
                            scale={4}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_OPTIMAL_MOISTURE_AT_HARVEST, value)
                            }
                        />
                    </SubSection>
                </Section>
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.cropNameAlias)}
                >
                    <SubSection>
                        <AliasList
                            formatMessage={formatMessage}
                            aliasTextLabel="cropNameAlias"
                            record={(this.crop[model.PROPS_CROP_ALIAS] || []).map((aliasName) => ({
                                aliasName,
                            }))}
                            itemListAlias={model.PROPS_CROP_ALIAS}
                            onTextChange={this.onTextChange}
                        />
                    </SubSection>
                </Section>
                <Section className="grid-section" headerText={formatMessage(messages.myAgData)}>
                    <SubSection>
                        <CommodityList
                            formatMessage={formatMessage}
                            commodityList={commodity}
                            commodityTypeList={commodityType}
                            intendedUseList={intendedUse}
                            croppingPracticeList={croppingPractice}
                            onTextChange={this.onChangeMyAgData}
                            itemListAlias={model.PROPS_MYAGDATA_COMMODITY}
                            record={crop[model.PROPS_MYAGDATA_COMMODITY]}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }

    renderDetailInfo3() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container section-column">
                <Section className="grid-section" headerText={formatMessage(messages.agrianID)}>
                    <SubSection>
                        <AgrianId
                            formatMessage={formatMessage}
                            record={(this.crop[model.PROPS_AGRIAN_ID] || []).map((agrianId) => ({
                                agrianId,
                            }))}
                            itemListAlias={model.PROPS_AGRIAN_ID}
                            onTextChange={this.onTextChange}
                        />
                    </SubSection>
                </Section>
                <Section headerText={formatMessage(messages.defaultColor)}>
                    <SubSection>
                        <ColorPicker
                            value={this.crop[model.PROPS_DEFAULT_MAP_COLOR] || ""}
                            onChange={(colorCode) =>
                                this.onTextChange(model.PROPS_DEFAULT_MAP_COLOR, colorCode)
                            }
                        />
                    </SubSection>
                </Section>
                <Section className="grid-section" headerText={formatMessage(messages.cropPhotos)}>
                    <SubSection>
                        <PhotoList
                            formatMessage={formatMessage}
                            record={this.crop[model.PROPS_CROP_PHOTO_LIST]}
                            itemListAlias={model.PROPS_CROP_PHOTO_LIST}
                            onTextChange={this.onTextChange}
                            useNewApi={true}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    }

    render() {
        return (
            <div className="add-edit-panel">
                {this.renderCropInfo()}
                <div className="section-container">
                    {this.renderDetailInfo1()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo2()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo3()}
                </div>
            </div>
        );
    }
}

// Add Intl
export default injectIntl(AddEditPanel);
